import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const TaskFiltering = () => {
    return (
        <Page className="about-page task-filtering-page" pageKey="task-filtering">
            <AboutTemplate>
                <Typography variant="h1">Task filtering</Typography>
                <Section>
                    <Typography variant="body1">Task filtering is a way to see a more specific set of tasks, based on their <Link href="/about/features/tasks/labels" variant="body1">labels</Link>.</Typography>
                    <ImageAndDescription imageName="filter-button" pageKey="taskFiltering" description="Click the filter button at the top of your stack to begin filtering tasks" />

                    <Typography variant="body1">Clicking the filter button will open a dropdown.</Typography>
                    <ResponsiveImage imageName="filter-dropdown" pageKey="taskFiltering" size="medium" />
                </Section>

                <Section title="Including labels">
                    <ImageAndDescription imageName="include" pageKey="taskFiltering" description="You can include labels in the filter by clicking the checkbox of the labels you want to include in the 'Include' column." />
                    <Typography variant="body1">Once you have selected a label, only tasks with that label will be displayed in the stack.</Typography>
                </Section>

                <Section title="Excluding labels">
                    <ImageAndDescription imageName="exclude" pageKey="taskFiltering" description="You can exclude labels in the filter by clicking the checkbox of the labels you want to include in the 'Exclude' column." />
                    <Typography variant="body1">Once you have selected a label, only tasks without that label will be displayed in the stack.</Typography>
                </Section>

                <Section title="Combining includes and excludes">
                    <ImageAndDescription imageName="combined" pageKey="taskFiltering" description="You can combine includes and excludes to get a more specific set of tasks." />
                    <Typography variant="body1">Only tasks with the included labels and without the excluded labels will be displayed in the stack.</Typography>
                </Section>

                <Section title="Clearing the filter">
                    <ImageAndDescription imageName="clear" pageKey="taskFiltering" description="You can clear the filter by clicking the 'Clear filters' button at the bottom of the dropdown." />
                    <Typography variant="body1">The filter will be removed and all tasks will be displayed in the stack.</Typography>
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default TaskFiltering;