import { Preferences } from '@capacitor/preferences';
import calculateNotificationTimes from "./calculateNotificationTimes";
import {LocalNotifications} from "@capacitor/local-notifications";

const cleanUpExpiredNotifications = async (tasks) => {
    const currentTime = Date.now();

    for (const task of tasks) {
        const notificationStatus = await Preferences.get({ key: `task_${task.taskId}_notifications` });

        if (notificationStatus && notificationStatus.value) {
            const { nearlyDueNotificationId, overdueNotificationId } = JSON.parse(notificationStatus.value);
            const { nearlyDue, overdue } = calculateNotificationTimes(task.deadline);

            // Check if notifications have expired
            if (nearlyDue.getTime() < currentTime && overdue.getTime() < currentTime) {
                // Cancel the expired notifications
                await LocalNotifications.cancel({
                    notifications: [
                        { id: nearlyDueNotificationId },
                        { id: overdueNotificationId }
                    ]
                });

                // Remove expired notification from Preferences
                await Preferences.remove({ key: `task_${task.taskId}_notifications` });
            }
        }
    }
};

export default cleanUpExpiredNotifications;