import { createStore } from 'redux'
import rootReducer from "./reducers";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('store');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify({
            taskLists: state.taskLists,
            taskLabels: state.taskLabels
        });
        localStorage.setItem('store', serializedState);
    } catch(err) {
        // ignore write errors
    }
};

const persistedState = loadState();
const store = createStore(rootReducer, persistedState);
store.subscribe(() => {
    saveState(store.getState());
});

const dispatch = store.dispatch;
export default store;
export {dispatch};

