import React, { useState } from "react";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert_black_24dp.svg";
import Button from "../../input/button";
import AttributeIndicator from "./AttributeIndicator";
import {Box} from "@mui/material";
import NotesContainer from "../taskInputs/notes/NotesContainer";
import {ReactComponent as TargetIcon} from "../../../assets/icons/crosshair.svg";
import {Emoji} from "emoji-picker-react";
import useWindowDimensions from "../../../helpers/window";

const Task = ({ item }) => {
    const { name, notes: initialNotes, attributes, labels, targeted, preventAnimation, deadlineDisplay, closeToDeadline, emoji } = item;

    const { difficulty, importance } = attributes;

    const { width } = useWindowDimensions();
    const [animation, setAnimation] = useState("animate__fadeIn");
    const [completed, setCompleted] = useState(false);
    const [isTargeted, setIsTargeted] = useState(targeted);
    const [notes, setNotes] = useState(initialNotes);

    const onCompleteClick = () => {
        setAnimation("animate__backOutRight animate__faster");
        setTimeout(() => {
            setCompleted(true)
        }, 700)
    };

    const onTargetClick = () => {
        setIsTargeted(!isTargeted);
    };

    const onCancelClick = () => {
        setAnimation("animate__backOutRight animate__faster");
        setTimeout(() => {
            setCompleted(true)
        }, 700)
    };

    const onEditClick = () => {

    };

    const onNoteStatusChange = ({id, status}) => {
        const newNotes = {...notes};
        const newNote = newNotes[id];
        newNote.completed = status;

        setNotes(newNotes);
    };

    const onNoteDeleted = (id) => {
        const newNotes = {...notes};
        delete newNotes[id]

        setNotes(newNotes);
    };

    if(completed) {
        return null;
    }

    return (
        <div
            className={`animate__animated ${animation && !preventAnimation ? animation : ""} task ${isTargeted ? "task--targeted" : ""} ${closeToDeadline ? "task--close-to-deadline" : ""}${emoji ? " task--has-emoji" : ""}`}
        key={name}>
            <div className={`task__header`}>
                <div className="task__header-emoji-border-mask-right"/>
                <div className="task__header-emoji-border-mask-bottom"/>
                {emoji && <div className="task_emoji-badge">
                    <div className="task_emoji-background"/>
                    <div className="task_emoji-container">
                        <Emoji unified={emoji} size={width > 768 ? 30 : 24}/>
                    </div>
                </div>}
                <div className={`task__header-contents`}>
                    {targeted && <TargetIcon className="task__header-target-icon"/>}
                    <h4 className="task__title">{name}</h4>
                    <div className="task__label-container scroller">
                        {labels && labels.map(label => (
                            <div className="label-pill" key={`task-label__${label}`}>
                                <p className="label-pill__text">{label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="task__body">
                <div className="task__attributes">
                    <div className="task__attribute">
                        <p className="task__attribute-title">Difficulty</p>
                        <AttributeIndicator value={difficulty} />
                    </div>
                    <div className="task__attribute">
                        <p className="task__attribute-title">Importance</p>
                        <AttributeIndicator value={importance} />
                    </div>
                </div>
                <div className="task__right-column">
                    <div className="task__notes-container">
                        {notes &&
                            <NotesContainer
                                notes={notes}
                                showInput={false}
                                onNoteStatusChange={onNoteStatusChange}
                                onNoteDeleted={onNoteDeleted}
                                mock={true}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className={`task__action-bar`}>
                {deadlineDisplay &&
                    <Box className="task__action-deadline-container">
                        <AlertIcon className={`task__action-deadline-icon ${closeToDeadline ? "task__action-deadline-icon--close-to-deadline" : ""}`}/>
                        <p className={`task__action-deadline-time ${closeToDeadline ? "task__action-deadline-time--close-to-deadline" : ""}`}>{deadlineDisplay}</p>
                    </Box>
                }
                <Box className="task__action-button-container">
                    <Button text="Cancel" color="red" icon="trash" collapsable={true} confetti={true} onClick={onCancelClick}/>
                    <Button text="Edit" color="orange" icon="edit" collapsable={true} confetti={true} onClick={onEditClick}/>
                    <Button text={targeted ? "Detarget" : "Target"}
                            color="yellow"
                            icon={targeted ? "detarget" : "target"}
                            collapsable={true}
                            confetti={true}
                            onClick={onTargetClick}
                    />
                    <Button text="Done" color="green" icon="tick" collapsable={true} confetti={true} onClick={onCompleteClick}/>
                </Box>
            </div>
        </div>
    )
};

export default Task
