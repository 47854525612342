import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import App from './App';
import './index.css';
import "animate.css";
import {initialiseFirebase} from "./helpers/firebase";
import isToday from "dayjs/plugin/isToday";
import dayjs from "dayjs";
import {Capacitor} from "@capacitor/core";
import {initialisePushNotifications} from "./helpers/push-notifications";
import waitForLocalNotificationsPermission from "./notifications/waitForLocalNotificationsPermission";

dayjs.extend(isToday);

initialiseFirebase();

if(Capacitor.isNativePlatform()) {
    // TODO this needs splitting out somewhere, but has been put in a function to avoid blocking the main thread for now
    const notifications = async () => {
        initialisePushNotifications();
        await waitForLocalNotificationsPermission();
    }
    void notifications();
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();