import React from "react";
import TaskSelection from "./TaskSelection";
import MindsetSelection from "./MindsetSelection";
import { useSelector } from "react-redux";
import socket from "../../../socket";
import { dispatch } from "../../../redux/store";
import {setMindset} from "../../../redux/actions/user";

const GetTaskOverlay = () => {
    const { mindset } = useSelector(state => state.user);

    const onUpdateMindset = (updatedMindset) => {
        dispatch(setMindset(updatedMindset));
        socket.emit("updateMindset", { ...updatedMindset });
    }

    return (
        <div className="get-task-overlay">
            <div className="get-task__container">
                <MindsetSelection onUpdateMindset={onUpdateMindset} initialMindset={mindset}/>
                <TaskSelection mindset={mindset} />
            </div>
        </div>
    )
}

export default GetTaskOverlay;
