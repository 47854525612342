import Page from "./Page";
import {useEffect} from "react";
import socket from "../socket";
import {PageConstants} from "../constants";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dispatch} from "../redux/store";
import {setUserIsAdmin} from "../redux/actions/admin";
import {Grid, Typography} from "@mui/material";
import AdminTabs from "../components/admin/AdminTabs";
import GlobalStats from "../components/admin/GlobalStats";

const AdminPage = () => {
    const authenticated = useSelector(state => state.user.authenticated);
    const user = useSelector(state => state.user.activeUser);
    const { isAdmin } = useSelector(state => state.admin);
    const navigate = useNavigate();

    useEffect(() => {
        if((!authenticated && user === undefined)) {
            //We check specifically for undefined as user begins as null, and is set to undefined on auth failure
            navigate("/")
        }

        if(authenticated) {
            socket.emit("pageLoad", {page: PageConstants.ADMIN_PAGE}, ({ isAdmin }) => {
                if(!isAdmin) {
                    navigate("/");
                }

                dispatch(setUserIsAdmin(isAdmin));
            });
        }
    }, [authenticated, user]);

    return (
        <Page className="admin-page" pageKey="admin">
            <h1 className="admin-page_title">Admin</h1>

            {!isAdmin ?
                <Typography variant="body1" style={{ color: "#FFFFFF", marginLeft: 16}}>
                    You are not authorized to view this page.
                </Typography>
                :
                <Grid container>
                    <Grid item xs={12} md={6} className="admin-page_card-column" sx={{mb: {xs: 4, md: 0}}}>
                        <AdminTabs />
                    </Grid>
                    <Grid item xs={12} md={6} className="admin-page_card-column">
                        <GlobalStats />
                    </Grid>
                </Grid>
            }
        </Page>
    );
}

export default AdminPage;
