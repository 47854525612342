const Video = ({ embedId, videoTitle }) => {
    return (
        <div className="video-responsive-container">
            <div className="video-responsive">
                <iframe src={`https://www.youtube.com/embed/${embedId}`}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Video;