import React, { useState } from "react";
import {ReactComponent as TargetIcon} from "../../assets/icons/crosshair.svg";


const Toggle = ({value, onValueChange}) => {
    const [toggleActive, setToggleActive] = useState(value);

    const onSliderClick = () => {
        const newValue = !toggleActive;
        setToggleActive(newValue);
        onValueChange(newValue)
    }

    return (
        <div className={`toggle ${toggleActive ? "toggle--active" : ""}`} onClick={onSliderClick}>
            <TargetIcon className="toggle__icon"/>
            <div className={`toggle__slider`}/>
        </div>
    )
};

export default Toggle;
