import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import { Typography } from "@mui/material";

const FilteringTasks = () => {
    return (
        <Page className="about-page filtering-tasks-page" pageKey="filtering-tasks">
            <AboutTemplate>
                <Typography variant="h1">Filtering tasks</Typography>
            </AboutTemplate>
        </Page>
    );
}

export default FilteringTasks;