import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import Section from "../../components/about/Section";

const Tasks = () => {
    return (
        <Page className="about-page tasks-information-page" pageKey="tasks-information">
            <AboutTemplate>
                <Section>
                    <Typography variant="h1">Tasks</Typography>
                    <Typography variant="body1">Tasks are a "unit" of work in Mindstack. Think of them as a single job, which once done, can be ticked off your to-do list.</Typography>

                    <ResponsiveImage imageName="task" pageKey="tasks" size="large" />
                </Section>

                <Typography variant="body1">Mindstack will display your tasks in a <Link href="/about/features/stacks" variant="body1">stack</Link> and prioritise them, so you can easily focus on the tasks which are most important to you.</Typography>

                <Typography variant="body1">Add <Link href="/about/features/tasks/attributes" variant="body1">attributes</Link> to tasks to give you more control over where tasks will appear in your to-do list and help you target tasks based on your <Link href="/about/features/mindset" variant="body1">mindset</Link>.</Typography>

                <Typography variant="body1"><Link href="/about/features/task-targetting" variant="body1">Target a task</Link> to set a task as a goal and commit to focussing on it until it is complete.</Typography>

                <Typography variant="body1"><Link href="/about/features/tasks/labels" variant="body1">Labels</Link> can be added to tasks to group tasks together and <Link href="/about/features/task-filtering" variant="body1">filter</Link> tasks based on their label.</Typography>

                <Typography variant="body1">Add <Link href="/about/features/tasks/deadlines" variant="body1">deadlines</Link> to tasks to help make sure you get your tasks done on time. Tasks can also be made to <Link href="/about/features/tasks/recurring-tasks" variant="body1">recur</Link> if they frequently re-appear on your to-do list.</Typography>

                <Typography variant="body1"><Link href="/about/features/tasks/notes" variant="body1">Notes</Link> can be added to tasks to keep hold of information relevant to your task</Typography>
            </AboutTemplate>
        </Page>
    );
}

export default Tasks;