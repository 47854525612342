import AboutMenuItem from "./AboutMenuItem";
import {Link} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useDispatch, useSelector} from "react-redux";
import {setSelectedMenuItem} from "../../../../redux/actions/about";
import {useState} from "react";

const isChildRoute = (parentRoute, childRoute) => {
    const parentParts = parentRoute.split('/');
    const childParts = childRoute.split('/');
    return parentParts.every((part, index) => part === childParts[index]);
};

const AboutSubMenu = ({ menuItem, level }) => {
    const dispatch = useDispatch();
    const { selectedMenuItem } = useSelector(state => state.about);
    const [expanded, setExpanded] = useState(selectedMenuItem ? isChildRoute(menuItem.route, selectedMenuItem) : false)

    return (
        <Accordion style={{backgroundColor: "none", boxShadow: "none"}} expanded={expanded} onChange={(e, isExpanded) => {setExpanded(isExpanded)}}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon stroke="white" color="white" sx={{width: "40px"}}/>}>
                    <Link to={menuItem.route} className={`about-menu_submenu-header ${selectedMenuItem === menuItem.route ? "about-menu-item--selected" : ""}`}>
                        <Typography variant="popoverMenuItem" onClick={() => dispatch(setSelectedMenuItem(menuItem.route))}>
                            {menuItem.title}
                        </Typography>
                    </Link>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className="about-sub-menu">
                        {menuItem.children.map((item, index) => {
                            if(item.children) {
                                return (
                                    <AboutSubMenu
                                        menuItem={item}
                                        routeRoot={item.route}
                                        key={index}
                                        level={level + 1}
                                    />
                                )
                            } else {
                                return (
                                    <AboutMenuItem
                                        route={item.route}
                                        key={index}
                                        item={item}
                                        level={level + 1}
                                    />
                                )
                            }

                        })}
                    </ul>
                </AccordionDetails>
        </Accordion>
    )
}

export default AboutSubMenu;