import React from "react";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit_black_24dp.svg";


const EditButton = ({ onClick }) => {
    return (
        <div className="button button--edit" onClick={onClick}>
            <EditIcon className="button__edit-icon" />
        </div>
    )
};

export default EditButton