import React from "react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close_black_24dp.svg";
import {dispatch} from "../../redux/store";
import {removeBanner} from "../../redux/actions/banners";

const Banner = ({ bannerKey, bannerText }) => {
    const onClose = () => {
        dispatch(removeBanner({key: bannerKey}))
    }

    return (
        <div className={"banner animate__animated animate__slideInDown animate__fast"}>
            <div></div>
            <h3 className="banner__text">{bannerText}</h3>
            <div>
                <CloseIcon className="banner__close-icon" onClick={onClose}/>
            </div>
        </div>
    )
}

export default Banner;