import {LocalNotifications} from "@capacitor/local-notifications";
import getNotificationStatus from "./getNotificationStatus";
import {Preferences} from "@capacitor/preferences";

const removeNotificationsForTask = async (taskId) => {
    // Get list of notification associated with the task from preferences
    const notificationsForTask = await getNotificationStatus(taskId);

    if(!notificationsForTask) {
        return;
    }

    // Cancel each notification
    await LocalNotifications.cancel({ notifications: [{id: notificationsForTask.nearlyDueNotificationId}, {id: notificationsForTask.overdueNotificationId} ] });

    // Remove notification status from preferences
    await Preferences.set({ key: `task_${taskId}_notifications`, value: null });

    console.log("Cancelled notifications for task:", taskId);
}

export default removeNotificationsForTask;