import {Link} from "react-router-dom";
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedMenuItem} from "../../../../redux/actions/about";

const AboutMenuItem = ({ item, route, level }) => {
    const dispatch = useDispatch();
    const { selectedMenuItem } = useSelector(state => state.about);

    return (
        <li className={`about-menu-item about-menu-item--level-${level} ${selectedMenuItem === route ? "about-menu-item--selected" : ""}`}>
            <Link to={route} className={"about-menu-item-link"}>
                <Typography variant="popoverMenuItem" onClick={() => {dispatch(setSelectedMenuItem(item.route))}}>{item.title}</Typography>
            </Link>
        </li>
    )
}

export default AboutMenuItem;