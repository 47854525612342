import { PageConstants } from "../../constants";
import {dispatch} from "../../redux/store";
import {setRecurringTasks} from "../../redux/actions/recurringTasks";
import {setUsers} from "../../redux/actions/admin";

const pageData =  ({page, data}) => {
   switch(page) {
      case PageConstants.RECURRING_TASKS_PAGE: {
         dispatch(setRecurringTasks(data.recurringTasks));
         break;
      }
      case PageConstants.ADMIN_PAGE: {
         dispatch(setUsers(data.users));
         break;
      }
      default: {
         console.log("Invalid page loaded");
      }
   }
}

export default pageData;