import "firebase/analytics";
import * as firebaseui from 'firebaseui'
import { dispatch } from "../redux/store";
import {setDefinitions} from "../redux/actions/definitions";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import {initialiseFirebaseAuth, auth} from "./auth";

let firebaseUI = null;
let initialised = false;
export const initialiseFirebase = () => {
    if(!initialised) {
        let firebaseConfig = {
            apiKey: "AIzaSyAziLooNNQRWDNaUpuiKKzZExOWzun65d4",
            authDomain: "mindstack-368413.firebaseapp.com",
            databaseURL: "https://mindstack-368413-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "mindstack-368413",
            storageBucket: "mindstack-368413.appspot.com",
            messagingSenderId: "717481736708",
            appId: "1:717481736708:web:c7aac2416826a18a42d351",
            measurementId: "G-BX2BH0EMQZ"
        };
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        initialiseFirebaseAuth();
        firebaseUI = new firebaseui.auth.AuthUI(auth);

        getDefinitions(getDatabase(app));
        initialised = true;
    }
};

export const getDefinitions = (database) => {
    const definitionsRef = ref(database, "definitions");
    onValue(definitionsRef, (snapshot) => {
        const definitions = snapshot.val();
        dispatch(setDefinitions(definitions));
    });
}

export { firebaseUI };
