import Input from "../../../input";
import {ReactComponent as PlusIcon} from "../../../../assets/icons/add_black_24dp.svg";
import React, {useImperativeHandle, useRef, useState} from "react";
import Note from "./Note";

const NotesContainer = ({ showInput = true,  notes, onAddNote, onNoteStatusChange, onNoteDeleted, noteInputValueRef, mock }) => {
    const [noteInput, setNoteInput] = useState("")
    const inputRef = useRef();

    useImperativeHandle(noteInputValueRef, () => ({
        getNoteValue: () => {
            return noteInput
        }
    }),[noteInput])

    const onNoteInputChange = (event) => {
        const newNoteInput = event.target.value;

        setNoteInput(newNoteInput)
    };

    const onAddButtonClicked = () =>  {
        if(!noteInput) {
            inputRef.current.focus();
        }
        onAddNote(noteInput);
        setNoteInput("")
    }

    return (
        <a className="notes-container">
            {showInput && <Input
                labelText={"New Note"}
                placeholder="Add Note"
                inputRef={inputRef}
                onChange={onNoteInputChange}
                value={noteInput}
                onEnterPressed={onAddButtonClicked}
                endAdornment={
                    <PlusIcon className="input__plus-button" onClick={onAddButtonClicked}/>
                }
            />}
            <div className="notes-container__notes">
                {notes && Object.values(notes).map((note) => {
                    return (
                        <Note
                            id={note.id}
                            text={note.text}
                            completed={note.completed}
                            onNoteStatusChange={onNoteStatusChange}
                            onNoteDeleted={onNoteDeleted}
                            key={note.id}
                            mock={mock}
                        />)
                })}
            </div>
        </a>
    )
}

export default NotesContainer;