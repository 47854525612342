import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import DeveloperNotes from "../../components/about/DeveloperNotes";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const TaskAttributes = () => {
    return (
        <Page className="about-page task-attributes-page" pageKey="task-attributes">
            <AboutTemplate>
                <Typography variant="h1">Task Attributes</Typography>
                <Typography variant="body1">Tasks can be set up with a set of attributes that help you organize and prioritize your to-do list.</Typography>

                <Section title="Importance">
                    <Typography variant="body1">Some tasks are more important than others.
                        Mindstack will allow you to set an "importance" value for the task which will influence where the task appears on your to-do list.
                        The higher a task's importance, the higher it appears in your stack.
                    </Typography>
                    <Typography variant="body1">
                        A task's importance can be between 1 and 10. A lower value means the task is less important, and a higher value means the task is more important.
                    </Typography>
                    <ImageAndDescription imageName="importance" pageKey="taskAttributes" description="A task with an importance of 1 means the task is barely worth doing, and a task with an importance of 10 means the task is critically important." />
                </Section>

                <Section title="Difficulty">
                    <Typography variant="body1">Some tasks are just harder to do.
                        Mindstack will allow you to set an "difficulty" value for the task which indicates how hard the task will be to complete.
                        If you set difficulty on all of your tasks, it will help you choose the right task to work on based on how much energy you have.
                    </Typography>
                    <Typography variant="body1">
                        Mindstack's <Link href="/about/features/task-suggestions" variant="body1">Task Suggestion</Link> feature uses your tasks' difficulties to suggest tasks based on your energy level and general <Link href="/about/features/mindset" variant="body1">mindset</Link>.
                    </Typography>
                    <Typography variant="body1">
                        A task's difficulty can be between 1 and 10. A lower value means the task is easier, and a higher value means the task is harder.
                    </Typography>
                    <ImageAndDescription imageName="difficulty" pageKey="taskAttributes" description="A task with a difficulty of 1 means the task is very simple, and a task with an importance of 10 means the task will take a lot of effort." />
                </Section>

                <Section title="Fun">
                    <Typography variant="body1">Some tasks are more enjoyable than others.
                        Mindstack will allow you to set an "fun" value for the task which indicates how much you will enjoy working on the task.
                        If you set fun on all of your tasks, it will help you choose the right task to work on based on what sort of <Link href="/about/features/mindset" variant="body1">mindset</Link> you are in.
                    </Typography>
                    <Typography variant="body1">
                        Mindstack's <Link href="/about/features/task-suggestions" variant="body1">Task Suggestion</Link> feature considers how fun your tasks are, which helps it suggest tasks based on your <Link href="/about/features/mindset" variant="body1">mindset</Link>.
                    </Typography>
                    <Typography variant="body1">
                        A task's fun can be between 1 and 10. A lower value means the task is less enjoyable, and a higher value means you will have more fun whilst completing the task.
                    </Typography>
                    <ImageAndDescription imageName="fun" pageKey="taskAttributes" description="A task with a fun of 1 means the task is deeply boring, and a task with an fun of 10 means you will have a great time whilst completing it." />
                </Section>

                <DeveloperNotes>
                    <Typography variant="body1">One of the most important things I've learnt whilst using Mindstack myself is to use the full spread of attribute ranges (1-10).</Typography>
                    <Typography variant="body1">I began by being quite extreme with my attributes. Everything was either a 1-2, or a 9-10.</Typography>
                    <Typography variant="body1">This made my stack quite saturated and it was harder to tell what was actually important.</Typography>
                    <Typography variant="body1">I found that when I reset my attributes to more sensible levels, relative to other tasks, it became much easier to get a proper view of my task list.</Typography>
                    <Typography variant="body1">Take some time to tweak your task attributes, using the full scale of 1-10, and Mindstack will work better for you.</Typography>
                </DeveloperNotes>
            </AboutTemplate>
        </Page>
    );
}

export default TaskAttributes;