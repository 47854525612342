import Tooltip from "../../tooltip";
import Toggle from "../../toggle";
import React from "react";

const TargetToggleContainer = ({ targeted, setTargeted }) => {
    return(
        <div className="target-toggle-container">
            <div className="target-label-container">
                <p className="target-toggle-label">Targeted</p>
                <Tooltip text="Target a task to prioritise it and bring it to the top of your task list"/>
            </div>
            <Toggle value={targeted} onValueChange={setTargeted}/>
        </div>
    )
}

export default TargetToggleContainer;