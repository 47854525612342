import React from "react";
import List from "./List";
import Header from "./Header";

const TaskList = ({ listId, listName, tasks, searchTerm }) => {
    return (
        <div className="task-list-container" key={listId}>
            <Header title={listName} />
            <List tasks={tasks} listId={listId} searchTerm={searchTerm} />
        </div>
    )
};

export default TaskList
