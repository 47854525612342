import aboutConfig from '../config/about-config';
import { createElement } from "react";


const getRoutesFromConfig = (config) => {
    let routes = [];
    for (let item of config) {
        if (item.component) {
            routes.push({
                path: item.route,
                element: createElement(require(`../pages/about/${item.component}.js`).default)
            });
        }
        if (item.children) {
            routes = routes.concat(getRoutesFromConfig(item.children));
        }
    }
    return routes;
}

export const getAboutRoutes = () => {
    return getRoutesFromConfig(aboutConfig);
}