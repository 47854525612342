import {SET_OVERLAY_VISIBLE} from "../constants/overlay"

const initialValue = {
    visible: false,
    overlayType: null,
    overlayMode: null
};

export default (state = initialValue, action) => {
    const { type } = action;
    switch (type) {
        case SET_OVERLAY_VISIBLE: {
            const {visible, overlayType, overlayMode, overlaySource, ...rest} = action;
            return {
                ...state,
                visible,
                overlayType: visible ? overlayType : null,
                overlayMode: visible ? overlayMode : null,
                overlaySource: visible ? overlaySource : null,
                ...rest
            };
        }
        default:
            return state
    }
}