import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import DeveloperNotes from "../../components/about/DeveloperNotes";
import Video from "../../components/about/Video";
import Section from "../../components/about/Section";
import Button from "../../components/input/button";
import React from "react";

const WhatIsMindstack = () => {
    return (
        <Page className="about-page what-is-mindstack-page" pageKey="what-is-mindstack">
            <AboutTemplate>
                <div className="about-page_section">
                    <Typography variant="h1">What is Mindstack?</Typography>
                    <Video embedId="NYtjttnp1Rs" videoTitle="What is Mindstack?" />
                    <Typography variant="body1">Mindstack is a productivity app designed for people who struggle to stay
                        productive.</Typography>
                    <Typography variant="body1">For the people who are tired of endless sticky notes and scattered to-do
                        lists. For the people who are wondering where to start.</Typography>
                </div>

                <div className="about-page_section">
                    <Typography variant="h2">What does it do?</Typography>
                    <Typography variant="body1">Mindstack provides a place to keep all of your tasks in once place and a
                        way to stay focused on the tasks that matter most.</Typography>
                    <Typography variant="body1">Tasks are kept in a "stack" and automatically ordered based on their
                        importance and urgency. It is very easy to see, at a glance, which tasks you should probably
                        prioritize</Typography>
                    <Typography variant="body1">You can add deadlines, labels and notes to your tasks to help you stay
                        organized, and tasks can be targeted to help you stay focused on your near-term
                        goals.</Typography>
                    <Typography variant="body1">Read more about <Link href="features" variant="body1">Mindstack's features</Link></Typography>
                </div>

                <Section>
                    <Button text="Start using Mindstack" color="orange" link="/tasks"/>
                </Section>

                <DeveloperNotes>
                    <Typography variant="body1">I'm Sam, a web developer from Manchester, and I made
                        Mindstack for myself.</Typography>
                    <Typography variant="body1">I've always struggled to stay productive and keep track of my tasks.
                        I've tried sticky-notes and paper lists, but they never really worked for me. Lists get
                        misplaced or forgotten about, and the sight of sticky-notes stuck everywhere can be a little
                        overwhelming. </Typography>
                    <Typography variant="body1">Digital calendars & note lists I find more useful, but they still lacked
                        a sort of "bigger picture" view of everything I needed to do. Tasks slide down the list and I
                        sometimes lose sight of important things, buried under more menial tasks.</Typography>
                    <Typography variant="body1">I made Mindstack to keep everything in one place, and automatically sort
                        things based on their importance so I can tell at a glance what I need to do, and it has grown
                        from there </Typography>
                    <Typography variant="body1">Not everyone will find Mindstack useful, but personally I've found it
                        has helped quite a lot, and perhaps it can help you get more focused too</Typography>
                    <Typography variant="body1">If you have any feedback or suggestions, please don't hesitate to join our <Link href="community" variant="body1">Discord community</Link> and let me know what you think. I would really appreciate any help in making Mindstack as useful as
                        I would really appreciate any help in making Mindstack as useful as
                        it can be</Typography>
                </DeveloperNotes>

            </AboutTemplate>
        </Page>
);
}

export default WhatIsMindstack;