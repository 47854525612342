import React from "react";
import { TextField } from "@mui/material";

const Input = ({ labelText, placeholder, value, onChange, isArea, autoFocus, onEnterPressed, startAdornment, endAdornment, inputRef, readOnly = false }) => {
    const onKeyDown = (e) => {
        if (onEnterPressed && e.key === 'Enter') {
            onEnterPressed();
        }
    }

    return (
        <TextField
            label={labelText}
            placeholder={placeholder}
            onChange={onChange}
            multiline={isArea}
            value={value}
            autoFocus={autoFocus}
            fullWidth={true}
            variant="standard"
            inputRef={inputRef}
            InputProps={{
                startAdornment: startAdornment,
                endAdornment: endAdornment
            }}
            inputProps={{
                className: "text-field__input",
                onKeyDown,
                readOnly
            }}
        />
    )
};

export default Input;