import Button from "../input/button";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MockTask from "../tasks/taskCard/MockTask";
import useWindowDimensions from "../../helpers/window";

const Splash = () => {
    const user = useSelector(state => state.user.activeUser);
    const { width } = useWindowDimensions();

    const mockTasks = useMemo(() => [
        {
            attributes: {
                difficulty: 7,
                fun: 4,
                importance: 10,
            },
            completed: false,
            name: "An important task",
            targeted: true,
            labels: ["Chore"],
            notes: {
                1: {
                    completed: false,
                    text: "Don't forget to do this",
                    id: 1,
                },
                2: {
                    completed: true,
                    text: "Done!",
                    id: 2,
                }
            }
        },
        {
            attributes: {
                difficulty: 4,
                fun: 5,
                importance: 7,
            },
            completed: false,
            name: "Task with a deadline",
            targeted: false,
            labels: ["Work"],
            deadlineDisplay: width > 768 ? "3 Days" : "3D",
            closeToDeadline: true,
        },
        {
            attributes: {
                difficulty: 3,
                fun: 5,
                importance: 4,
            },
            completed: false,
            name: "Do the thing",
            labels: ["Fun"],
            targeted: false,
            notes: {
                1: {
                    completed: false,
                    text: "A note",
                    id: 1,
                },
                2: {
                    completed: true,
                    text: "A completed note",
                    id: 2
                }
            }
        }
    ], [width])

    return (
        <div className="splash">
            <div className="splash_container">
                <div className="splash_text-container">
                    <h1 className="splash_title">Mindstack</h1>
                    <h2 className="splash_subtitle">Stay productive, no matter your mood.</h2>
                    {user ?
                        <>
                            <Button text="Learn about Mindstack" color="green" link="/about/what-is-mindstack"/>
                            <div className="splash_button-seperator" />
                            <Button text="Go to Tasks" color="orange" link="/tasks"/>
                        </>
                        :
                        <>
                            <Button text="Learn about Mindstack" color="green" link="/about/what-is-mindstack"/>
                            <div className="splash_button-seperator" />
                            <Button text="Login or Signup" color="orange" link="/login"/>
                        </>
                    }
                </div>
                <div className="splash_mock-task-container">
                    <div className="splash_mock-task-mask" onClick={() => {}}/>
                    <div className="splash_mock-task-list">
                        {
                            mockTasks.map(task => <MockTask item={task} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Splash;
