import {SET_SESSIONS, SET_ACTIVE_SESSIONS, SET_USER_IS_ADMIN, SET_USERS, SET_SESSION_HISTORY} from "../constants/admin";

const initialValue = {
    analytics: {
        sessions: {},
        activeSessions: 0,
        sessionHistory: []
    },
};

export default (state = initialValue, action) => {
    const newState = {...state};

    switch (action.type) {
        case SET_USER_IS_ADMIN:
            newState.isAdmin = action.isAdmin;
            return newState;
        case SET_USERS:
            newState.users = action.users;
            return newState;
        case SET_SESSIONS:
            newState.analytics.sessions = action.sessions;
            return newState;
        case SET_ACTIVE_SESSIONS:
            newState.analytics.activeSessions = action.activeSessions;
            return newState;
        case SET_SESSION_HISTORY:
            newState.analytics.sessionHistory = action.sessionHistory;
            return newState;
        default:
            return state
    }
}