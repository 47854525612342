import { createTheme } from "@mui/material";
import MuiCard from "./components/muiCard";
import {MuiAccordion, MuiAccordionSummary} from "./components/muiAccordion";
import MuiTextField from "./components/muiTextField";
import createTypography from "./typography";
import MuiLink from "./components/muiLink";
import palette from "./palette";

const paletteTheme = createTheme(({
    palette
}));

const theme = createTheme(paletteTheme, {
    components: {
      MuiCard,
      MuiAccordion,
      MuiAccordionSummary,
      MuiTextField,
      MuiLink
    },
    typography: createTypography(paletteTheme)
});

export default theme;
export { paletteTheme };