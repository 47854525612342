import React, { useState } from "react";
import { Link } from "react-router-dom";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit_black_24dp.svg";
import {ReactComponent as TickIcon} from "../../../assets/icons/check_black_24dp.svg";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close_black_24dp.svg";
import {ReactComponent as TargetIcon} from "../../../assets/icons/crosshair.svg";
import {ReactComponent as DetargetIcon} from "../../../assets/icons/crosshair-crossed.svg";
import {ReactComponent as TrashIcon} from "../../../assets/icons/trash.svg";
import {ReactComponent as RefreshIcon} from "../../../assets/icons/refresh-cw.svg";
import {ReactComponent as AddIcon} from "../../../assets/icons/add_black_24dp.svg";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';


const Button = (props) => {
    const {
        text,
        color,
        size,
        icon,
        confetti,
        link,
        collapsable, //Collapses just to an icon at smaller screen sizes
        disabled,
        onClick,
    } = props;

    const [active, setActive] = useState(false);

    let iconElement;
    if(icon) {
        switch(icon) {
            case "edit": {
                iconElement = <EditIcon className="button__icon"/>;
                break;
            }
            case "tick": {
                iconElement = <TickIcon className="button__icon"/>;
                break;
            }
            case "add": {
                iconElement = <AddIcon className="button__icon"/>;
                break;
            }
            case "close": {
                iconElement = <CloseIcon className="button__icon"/>;
                break;
            }
            case "target": {
                iconElement = <TargetIcon className="button__icon button__no-fill"/>;
                break;
            }
            case "detarget": {
                iconElement = <DetargetIcon className="button__icon button__no-fill"/>;
                break;
            }
            case "trash": {
                iconElement = <TrashIcon className="button__icon button__no-fill"/>;
                break;
            }
            case "refresh": {
                iconElement = <RefreshIcon className="button__icon button__no-fill" />;
                break;
            }
            case "recur": {
                iconElement = <EventRepeatIcon className="button__icon"/>;
                break;
            }
            default:
                iconElement = null;
        }
    }

    const className = "button "
    + (disabled ? "button--disabled " : "")
    + (color ? `button--${color} ` : "")
    + (icon ? (collapsable ? `icon-button icon-button--collapsable ` : `icon-button `) : "")
    + (size ? `button--${size} ` : "")
    + (confetti ? `button--confetti ${active ? `button--active ` : ""}` : "")

    return (
        link ?
            <Link to={link}>
                <div className={className} onClick={onClick}>
                    <p className="button__text">{text}</p>
                    {iconElement ? iconElement : null}
                </div>
            </Link>
        :
            <div className={className} onClick={() => {
                if(disabled) {
                    return;
                }

                onClick && onClick();

                // Used for confetti
                setActive(true);
                setTimeout(() => {
                    setActive(false)
                }, 1000)
            }}>
                <p className="button__text">{text}</p>
                {iconElement ? iconElement : null}
            </div>
    )
};

export default Button