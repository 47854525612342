export const SET_TASKS = "SET_TASKS";
export const ADD_TASK = "ADD_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const SET_TASK_TRUE_ID = "SET_TASK_TRUE_ID" // Used to update client-side id of tasks with server-decided value
export const SET_ACTIVE_TASK = "SET_ACTIVE_TASK";
export const SET_TASK_CREATED_TIME = "SET_TASK_CREATED_TIME";

export const SET_TASK_ATTRIBUTE_VALUE = "SET_TASK_ATTRIBUTE_VALUE";
export const SET_TASK_TARGETED = "SET_TASK_TARGETED";
export const REMOVE_LABEL_FROM_TASK = "REMOVE_LABEL_FROM_TASK";
export const SET_TASK_PREVENT_ANIMATION = "SET_TASK_PREVENT_ANIMATION";