import React, {useEffect} from 'react';
import Page from "./Page";
import RecurringTask from "../components/tasks/recurringTask";
import {Grid} from "@mui/material";
import socket from "../socket";
import {PageConstants} from "../constants";
import {useSelector} from "react-redux";
import Button from "../components/input/button";
import {useNavigate} from "react-router-dom";

const RecurringTasksPage = () => {
    const navigate = useNavigate();
    const authenticated = useSelector(state => state.user.authenticated);
    const { recurringTasks } = useSelector(state => state.recurringTasks);

    useEffect(() => {
        if(authenticated) {
            socket.emit("pageLoad", { page: PageConstants.RECURRING_TASKS_PAGE });
        }
    }, [authenticated]);

    const recurringTaskList = Object.values(recurringTasks);

    return (
        <Page className="recurring-tasks-page" pageKey="recurring-tasks">
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <h1 className="recurring-tasks-page__title">Recurring Tasks</h1>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className="recurring-tasks-page__task-container scroller">
                    { recurringTaskList.length > 0 ? recurringTaskList.map(task => (
                        <RecurringTask
                            task={task}
                            key={task.taskId}
                        />
                    ))
                        : <p className="recurring-tasks-page__no-tasks-label">You have no recurring tasks</p>
                    }
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <div className="recurring-tasks-page__back-button-container">
                        <Button
                            text="Back to tasks"
                            color="orange"
                            size="huge"
                            onClick={() => navigate("/tasks")}
                        />
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
};

export default RecurringTasksPage;