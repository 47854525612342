import React from "react";
import LabelFilters from "./LabelFilters";
import CloseIcon from '@mui/icons-material/Close';

const TaskFilterMenu = ({ onClose }) => {
    return (
        <div className="task-filter-menu__filter-dropdown">
            <h2 className="task-filter-menu__title">Filters</h2>
            <CloseIcon
                className="task-filter-menu_close"
                onClick={onClose}
            />
            <LabelFilters />
        </div>
    )
}

export default TaskFilterMenu;
