import AboutTemplate from "../../components/about/AboutTemplate";
import DiscordIcon from "../../assets/icons/discord.png";
import Page from "../Page";
import { Typography } from "@mui/material";
import {Link} from "react-router-dom";

const Community = () => {
    return (
        <Page className="about-page community-page" pageKey="community">
            <AboutTemplate>
                <Typography variant="h1">Community</Typography>
                <Typography variant="body1">Mindstack is a community-driven platform that is built on the principles of collaboration and shared ideas.</Typography>
                <Typography variant="body1">We have our own ideas for Mindstack, based on what works for us. However we want to make Mindstack work for everyone.</Typography>
                <Typography variant="body1">If you have any ideas/feedback for Mindstack, or just want to drop in to say hey, then please join our Discord community</Typography>
                <Link to="https://discord.gg/zRSaASFPmB" target="_blank" className="discord-link">
                    <img className="discord-link_icon" src={DiscordIcon} />
                    <Typography variant="body1" className="discord-link_text">Mindstack Discord</Typography>
                </Link>
                <Typography variant="body1">We are a friendly bunch and would love to meet you and hear what you have to say.</Typography>
                <Typography variant="body1">We hope to see you soon</Typography>
                <Typography variant="body1" style={{color: "#197B68"}}><em>The Mindstack Team</em></Typography>
            </AboutTemplate>
        </Page>
    );
}

export default Community;