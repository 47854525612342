import { reorderList, moveElementToOtherList } from "./list"

export const moveDroppableToOtherList = (sourceList, destinationList, sourceDroppable, destinationDroppable) => {
    return moveElementToOtherList(sourceList, sourceDroppable.droppableId, destinationList, destinationDroppable.droppableId, sourceDroppable,
        sourceDroppable.index, destinationDroppable, destinationDroppable.index)
};

export const onDragEnd = (taskLists, setTaskLists) => result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
        return;
    }

    if (source.droppableId === destination.droppableId) {
        const listIndex = taskLists.findIndex((list) => list.listId === source.droppableId);
        const sourceList = taskLists[listIndex].tasks;

        const newTodoLists = Array.from(taskLists);

        newTodoLists[listIndex].tasks = reorderList(
            sourceList,
            source.index,
            destination.index
        );

        const sourceElementId = sourceList[source.index].taskId;
        const movementDescription = {
            taskId: sourceElementId,
            from: {
                listId: source.droppableId
            },
            to: {
                listId: destination.droppableId,
                index: destination.index
            }
        }

        setTaskLists(newTodoLists, movementDescription);
    } else {
        const sourceListIndex = taskLists.findIndex((list) => list.listId === source.droppableId);
        const sourceList = taskLists[sourceListIndex].tasks;

        const destinationListIndex = taskLists.findIndex((list) => list.listId === destination.droppableId);
        const destinationList = taskLists[destinationListIndex].tasks;

        const result = moveDroppableToOtherList(
            sourceList,
            destinationList,
            source,
            destination
        );

        const newTodoLists = Array.from(taskLists);
        const listIds = Object.keys(result);
        listIds.forEach(listId => {
            const index = taskLists.findIndex((list) => list.listId === listId);
            newTodoLists[index].tasks = result[listId]
        });

        const sourceElementId = sourceList[source.index].taskId;
        const movementDescription = {
            taskId: sourceElementId,
            from: {
                listId: source.droppableId
            },
            to: {
                listId: destination.droppableId,
                index: destination.index
            }
        }

        setTaskLists(newTodoLists, movementDescription);
    }
};