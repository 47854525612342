import { SET_SELECTED_MENU_ITEM } from "../constants/about";

const initialValue = {};

export default (state = initialValue, action) => {
    const newState = {...state};

    switch (action.type) {
        case SET_SELECTED_MENU_ITEM:
            newState.selectedMenuItem = action.selectedMenuItem;
            return newState;
        default:
            return state
    }
}