import React from 'react';
import Header from "../components/header";
import Overlay from "../components/overlay";
import { Box } from "@mui/material";
import Banners from "../components/banner/Banners";

const Page = ({className = "", pageKey, children}) => {
    return (
        <div className={`page ${className} scroller`}>
            <Header />
                <Box className="page__contents">
                    {children}
                </Box>
            <Overlay />
            <Banners />
        </div>
    )
};

export default Page;
