import { SET_TASK_LABELS, ADD_TASK_LABEL, REMOVE_TASK_LABEL } from "../constants/taskLabels"

export default (state = [], action) => {
    switch (action.type) {
        case SET_TASK_LABELS:
            return action.labels || [];
        case ADD_TASK_LABEL: {
            const newLabelArray = Array.from(state);
            newLabelArray.push(action.label);
            return newLabelArray;
        }
        case REMOVE_TASK_LABEL: {
            const newLabelArray = Array.from(state);
            const labelIndex = newLabelArray.indexOf(action.label);
            if(labelIndex > -1) {
                newLabelArray.splice(labelIndex, 1);
            }
            return newLabelArray;
        }

        default:
            return state
    }
}