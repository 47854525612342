export const MuiAccordion = {
    styleOverrides: {
        root: {
            width: "100%",
            "&.MuiPaper-root": {
                background: "none",
                border: "none",
                boxShadow: "none"
            }
        }
    }
}

export const MuiAccordionSummary = {
    styleOverrides: {
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "20px 0 0"
        }
    }
}