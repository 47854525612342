import aboutConfig from "../../../../config/about-config.json";
import AboutSubMenu from "./AboutSubMenu";
import AboutMenuItem from "./AboutMenuItem";

const AboutMenuItemList = () => {
    return (
        <ul className="about-menu-item-list scroller">
            {
                aboutConfig.map((item, index) => {
                        if (item.children) {
                            return (
                                <AboutSubMenu
                                    menuItem={item}
                                    key={index}
                                    level={1}
                                />
                            )
                        } else {
                            return (
                                <AboutMenuItem
                                    route={item.route}
                                    key={index}
                                    item={item}
                                    level={1}
                                />
                            )
                        }
                    }
                )}
        </ul>
    )
}

export default AboutMenuItemList;