import AboutTemplate from "../../components/about/AboutTemplate";
import {Link, Typography} from "@mui/material";
import Page from "../Page";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import Section from "../../components/about/Section";

const TaskActions = () => {
     return (
         <Page className="about-page task-actions-page" pageKey="task-actions">
            <AboutTemplate>
                <Section>
                    <Typography variant="h1">Task Actions</Typography>
                    <Typography variant="body1">Actions which you can perform on a task are found as coloured buttons at the bottom right of the task.</Typography>
                    <ResponsiveImage imageName="button-bar" pageKey="taskActions" size="large" shadow />
                </Section>

                <Section>
                <Typography variant="h2">Complete a task</Typography>
                    <Typography variant="body1">Once you have completed a task, you can mark the task as completed by clicking the "Complete" button.</Typography>
                    <Typography variant="body1">This will remove the task from your task list. Time to focus on something else.</Typography>
                    <ResponsiveImage imageName="complete" pageKey="taskActions" size="medium" shadow />
                </Section>

                <Section>
                    <Typography variant="h2">Edit a task</Typography>
                    <Typography variant="body1">Sometimes you may need to update tasks. Perhaps you need to add a deadline, or a task has become more important than it was when you created it.</Typography>
                    <Typography variant="body1">You can view the "Edit task" page, and begin making changes to your task by clicking the "Edit" button. </Typography>
                    <ResponsiveImage imageName="edit" pageKey="taskActions" size="medium" shadow />
                </Section>

                <Section>
                    <Typography variant="h2">Target a task</Typography>
                    <Typography variant="body1">When you commit to focussing on a task, you can <Link href="/about/features/task-targetting" variant="body1">target a task.</Link></Typography>
                    <Typography variant="body1">This will bring the task into focus on your task stack by separating it from the rest of your tasks.</Typography>
                    <Typography variant="body1">Target a task by clicking the "Target" button at the bottom of the task.</Typography>
                    <ResponsiveImage imageName="target" pageKey="taskActions" size="medium" shadow />
                </Section>

                <Section>
                    <Typography variant="h2">Cancel a task</Typography>
                    <Typography variant="body1">Sometimes you just need to get rid of a task.</Typography>
                    <Typography variant="body1">Cancel a task by clicking the "Cancel" button at the bottom of the task</Typography>
                    <ResponsiveImage imageName="delete" pageKey="taskActions" size="medium" shadow />
                </Section>
            </AboutTemplate>
        </Page>
     )
}

export default TaskActions;