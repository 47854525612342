import {Link, Typography} from "@mui/material";
import Page from "../Page";
import AboutTemplate from "../../components/about/AboutTemplate";
import ImageAndDescription from "../../components/about/ImageAndDescription";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";

const CreateATask = () => {
    return (
        <Page className="about-page task-actions-page" pageKey="task-actions">
            <AboutTemplate>
                <Typography variant="h1">Create a task</Typography>
                <Section>
                    <Typography variant="body1">When you become aware of something that needs doing, you can create a task to begin keeping track of it in Mindstack.</Typography>
                    <ImageAndDescription imageName="create-a-task" pageKey="createATask" description="Click the 'Create Task' button at the bottom of the screen to open the 'Create Task' screen" />
                </Section>

                <Section title="The Create Task page">
                    <Typography variant="body1">This is where you create your tasks to be added to your task list.</Typography>
                    <ResponsiveImage imageName="create-a-task-page" pageKey="createATask" size="large" />
                </Section>

                <Section title="Name your task">
                    <Typography variant="body1">You can give your task a descriptive name which will be used to identify the task on your task list.</Typography>
                    <Typography variant="body1">The task's name can be set using the text box at the top of the page</Typography>
                    <Typography variant="body1">If you are feeling zesty you can also add an emoji to represent the task using the smiley face icon</Typography>
                    <ResponsiveImage imageName="set-task-name" pageKey="createATask" size="large" />
                </Section>

                <Section title="Set your task's attributes">
                    <Typography variant="body1">Tasks can be assigned values to categorise how difficult, important and fun they are.
                        These <Link href={"/about/features/tasks/attributes"} variant="body1">task attributes</Link> are used to prioritise tasks on your task list, and help suggest tasks based
                        on your <Link href={"/about/features/mindset"} variant="body1">mindset</Link>.</Typography>
                    <ResponsiveImage imageName="task-attributes" pageKey="createATask" size="large" />
                </Section>

                <Section title="Add labels to your task">
                    <Typography variant="body1">You can also add <Link href={"/about/features/tasks/labels"} variant="body1">labels</Link> to your tasks to group them together and filter your task list based on their label.</Typography>
                    <ResponsiveImage imageName="add-labels" pageKey="createATask" size="large" />
                    <Typography variant="body1">You can begin to edit your tasks labels by clicking the "pen" icon to the right of the screen.</Typography>
                    <ResponsiveImage imageName="open-labels" pageKey="createATask" size="large" />
                </Section>

                <Section title="Stay on time">
                    <Typography variant="body1">Tasks can be given a <Link href={"/about/features/tasks/deadlines"} variant="body1">deadline</Link> to help make sure you get your tasks done on time. Tasks can also be made to <Link href={"/about/features/tasks/recurring-tasks"} variant="body1">recur</Link> if they frequently re-appear on your to-do list.</Typography>
                    <ResponsiveImage imageName="stay-on-time" pageKey="createATask" size="large" />
                </Section>

                <Section title="Add notes">
                    <Typography variant="body1">You can add notes to your tasks to provide more context or information about the task.</Typography>
                    <ResponsiveImage imageName="add-notes" pageKey="createATask" size="large" />
                </Section>

            </AboutTemplate>
        </Page>
    )
}

export default CreateATask;