const MuiCard = {
    styleOverrides: {
        root: {
            borderRadius: 16,
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
            padding: 16
        }
    }
}

export default MuiCard;