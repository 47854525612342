import { bubble as Menu } from 'react-burger-menu'
import {Link, useNavigate} from "react-router-dom";
import Button from "../input/button";
import {useSelector} from "react-redux";
import {signOut} from "../../helpers/auth";
import {dispatch} from "../../redux/store";
import {setUser} from "../../redux/actions/user";

const BurgerMenu = () =>  {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.activeUser);

    const onLogoutButtonClicked = () => {
        signOut();
        dispatch(setUser(null))
        navigate("/")
    }

    const onLoginButtonClicked = () => {
        navigate("/login")
    }

    return (
        <Menu>
            <Link to={"/"}>
                <h2>Home</h2>
            </Link>
            <Link to={"/tasks"}>
                <h2>Tasks</h2>
            </Link>
            <Link to={"/about/what-is-mindstack"}>
                <h2>About</h2>
            </Link>
            {user ?
                <Button text="Logout" color="red" size="huge" onClick={onLogoutButtonClicked} />
                :
                <Button text="Login or Signup" color="orange" size="huge" onClick={onLoginButtonClicked} />
            }
        </Menu>
    )
};

export default BurgerMenu
