import {SET_DEFINITIONS} from "../constants/definitions"

const initialValue = {
    attributes: {}
};

export default (state = initialValue, action) => {
    switch (action.type) {
        case SET_DEFINITIONS:
            return action.definitions;
        default:
            return state
    }
}