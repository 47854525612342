import {ADD_NEW_BANNER, REMOVE_BANNER} from "../constants/banners";

const initialValue = {};

export default (state = initialValue, action) => {
    const newState = {...state};

    switch (action.type) {
        case ADD_NEW_BANNER:
            newState[action.key] = action.banner;
            return newState;
        case REMOVE_BANNER:
            delete newState[action.key];
            return newState;
        default:
            return state
    }
}