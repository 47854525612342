import {Capacitor} from "@capacitor/core";
import {getAuth, indexedDBLocalPersistence, initializeAuth} from "firebase/auth";
import {getApp} from "firebase/app";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";
import socket from "../socket";
import {dispatch} from "../redux/store";
import {setUser} from "../redux/actions/user";

let auth;
export const initialiseFirebaseAuth = () => {
    if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        auth = getAuth();
    }
};

const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle();
  return result.user;
};

const signInWithEmailAndPassword = async () => {
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email: 'mail@example.com',
        password: '1234',
    });
    return result.user;
};

const signOut = async () => {
    await FirebaseAuthentication.signOut();
};

export const authenticateUser = () => {
    if(Capacitor.isNativePlatform()) {
        FirebaseAuthentication.addListener("authStateChange", async (result) => {
            if(result.user) {
                console.log("Authenticating");
                const idToken = (await FirebaseAuthentication.getIdToken()).token;
                socket.emit("authenticateSocket", {
                    idToken
                })

                const {uid, email, displayName} = result.user;
                dispatch(setUser({
                    uid,
                    email,
                    displayName
                }));
            } else {
                dispatch(setUser(undefined));
                console.log("logged out")
            }

        })
    } else {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    console.log("Authenticating");
                    socket.emit("authenticateSocket", {
                        idToken
                    })

                    const {uid, email, displayName} = user;
                    dispatch(setUser({
                        uid,
                        email,
                        displayName
                    }));
                }).catch(function(error) {
                    // Handle error
                });
            } else {
                // No user is signed in.
                dispatch(setUser(undefined));
                console.log("logged out")
            }
        });
    }
}

export {
    auth,
    signInWithGoogle,
    signInWithEmailAndPassword,
    signOut,
}