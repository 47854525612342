import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSelector} from "react-redux";

const SessionAnalytics = () => {
    const { users } = useSelector(state => state.admin);

    return (
        <div className="user-analytics">
                <div className={"user-analytics_user-list scroller"}>
                    <div>
                        {users.sort((a, b) => b.lastLoggedIn - a.lastLoggedIn).map((user) => {
                            const { uid, name, email, totalTasks, completedTasks, lastLoggedIn } = user
                            if(!uid) return null;
                            return (
                            <Accordion key={uid} className={"user-analytics_user-entry"}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {name ?
                                        <h4 className="user-analytics_username">{name}</h4>
                                        :
                                        <h4 className="user-analytics_username">{email}</h4>}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2"><strong>UID:</strong> {uid}</Typography>
                                    <Typography variant="body2"><strong>Name:</strong> {name}</Typography>
                                    <Typography variant="body2"><strong>Email:</strong> {email}</Typography>
                                    <Typography variant="body2"><strong>Last logged in:</strong> {new Date(lastLoggedIn).toLocaleString()}</Typography>
                                    <Typography variant="body2"><strong>Total Tasks:</strong> {totalTasks}</Typography>
                                    <Typography variant="body2"><strong>Completed Tasks:</strong> {completedTasks}</Typography>
                                </AccordionDetails>
                            </Accordion>
                            )
                        })}
                    </div>
            </div>
        </div>
    );
}

export default SessionAnalytics;