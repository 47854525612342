import React from "react";

import AttributeIndicator from "./taskCard/AttributeIndicator";
import {Emoji} from "emoji-picker-react";
import useWindowDimensions from "../../helpers/window";

const TaskSummary = ({ item, onClick, selected }) => {
    const { width } = useWindowDimensions();

    const { taskId, name, attributes, labels, targeted, emoji } = item;
    const { difficulty, importance } = attributes;

    return (
        <div
            className={`animate__animated animate__fadeIn task task-summary ${targeted ? "task--targeted" : ""} ${selected ? "task--targeted" : ""}${emoji ? " task--has-emoji" : ""}`}
            onClick={() => onClick(taskId)}
        >
            <div className={`task__header`}>
                <div className="task__header-emoji-border-mask-right"/>
                <div className="task__header-emoji-border-mask-bottom"/>
                {emoji && <div className="task_emoji-badge">
                    <div className="task_emoji-background"/>
                    <div className="task_emoji-container">
                        <Emoji unified={emoji} size={width > 768 ? 30 : 24}/>
                    </div>
                </div>}
                <div className={`task__header-contents`}>
                    <h4 className="task__title">{name}</h4>
                    <div className="task__label-container scroller">
                        {labels && labels.map(label => (
                            <div className="label-pill" key={`task-label__${label}`}>
                                <p className="label-pill__text">{label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="task__body">
                <div className="task__attributes">
                    <div className="task__attribute">
                        <p className="task__attribute-title">Difficulty</p>
                        <AttributeIndicator value={difficulty}/>
                    </div>
                    <div className="task__attribute">
                        <p className="task__attribute-title">Importance</p>
                        <AttributeIndicator value={importance}/>
                    </div>
                </div>
                <div className="task__right-column">
                    <div className="task__notes-container">

                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskSummary
