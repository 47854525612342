import React, {useMemo, useState} from "react";
import { useDispatch } from "react-redux";
import { Draggable } from 'react-beautiful-dnd';
import socket from "../../../socket";
import { TaskConstants } from "../../../constants";

import {
    removeTask,
    setActiveTask,
    setTaskAttributeValue,
    setTaskPreventAnimation,
    setTaskTargeted
} from "../../../redux/actions/tasks";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert_black_24dp.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/crosshair.svg";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Button from "../../input/button";
import AttributeIndicator from "./AttributeIndicator";
import {setOverlayVisible} from "../../../redux/actions/overlay";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useTaskDeadline from "../../../hooks/useTaskDeadline";
import NotesContainer from "../taskInputs/notes/NotesContainer";
import {Emoji} from "emoji-picker-react";
import useWindowDimensions from "../../../helpers/window";
import removeNotificationsForTask from "../../../notifications/removeNotificationsForTask";
import {Capacitor} from "@capacitor/core";

const Task = ({ item, index, isDraggable, forceListToResort }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { width } = useWindowDimensions();

    const { taskId, name, notes: initialNotes, attributes, labels, targeted, preventAnimation, deadline, created, frequency, emoji } = item;
    const { difficulty, importance } = attributes;

    const [animation, setAnimation] = useState("animate__fadeIn");
    const [isComplete, setIsComplete] = useState(false);
    const [notes, setNotes] = useState(initialNotes);

    const { deadlineDisplay, closeToDeadline } = useTaskDeadline(deadline, created, forceListToResort);

    const recurringLabelText = useMemo(() => {
        if(frequency) {
            return `Every${frequency.intervalCount === 1 ? "" : ` ${frequency.intervalCount}`} ${frequency.intervalDateType}${frequency.intervalCount > 1 ? "s": ""}`;
        }
        return "Does not recur";
    }, [frequency]);

    const onCompleteClick = () => {
        const onConfirmCallback = () => {
            if(isComplete) {
                return;
            }
            setIsComplete(true);

            //Wait 500ms for the completion animation to finish
            setTimeout(() => {
                // Then animate the task off screen
                dispatch(setTaskPreventAnimation({taskId, value: false}));
                setAnimation("animate__backOutRight animate__faster");

                setTimeout(async () => {
                    //Finally remove the task and notify server
                    dispatch(removeTask(taskId));
                    socket.emit("completeTask", {
                        taskId
                    });

                    if(Capacitor.isNativePlatform()) {
                        await removeNotificationsForTask(taskId);
                    }
                }, 400);
                dispatch(setOverlayVisible({visible: false}));
            }, 500);
        }

        dispatch(setOverlayVisible({
            visible: true,
            overlayType: "confirm",
            confirmationText: "Have you completed this task?",
            confirmCallback: onConfirmCallback
        }));
    };

    const onTargetClick = () => {
        socket.emit("setTaskTargeted", {
            taskId,
            targeted: !targeted
        });

        dispatch(setTaskTargeted({taskId, targeted: !targeted}));
    };

    const onCancelClick = () => {
        const onConfirmCallback = () => {
            dispatch(setTaskPreventAnimation({taskId, value: false}));
            setAnimation("animate__backOutRight animate__faster");
            setTimeout(async () => {
                dispatch(removeTask(taskId));
                socket.emit("cancelTask", {
                    taskId
                });
                await removeNotificationsForTask(taskId)
            }, 400);
            dispatch(setOverlayVisible({visible: false}))
        }

        dispatch(setOverlayVisible({
            visible: true,
            overlayType: "confirm",
            confirmationText: "Are you sure you want to cancel this task?",
            confirmCallback: onConfirmCallback
        }));
    };

    const onEditClick = () => {
        dispatch(setActiveTask(taskId));
        navigate("/edit-task");
    };

    const onAttributeChange = (attributeType) => {
        return (value) => {
            dispatch(setTaskAttributeValue({taskId, attributeType, value}))
            socket.emit("setTaskAttribute", {
                taskId,
                attributeType,
                value
            });
        }
    }

    const onNoteStatusChange = ({id, status}) => {
        const newNotes = {...notes};
        const newNote = newNotes[id];
        newNote.completed = status;

        setNotes(newNotes);

        socket.emit("setNoteCompletionStatus", {
            taskId,
            noteId: id,
            status
        });
    };

    const onNoteDeleted = (id) => {
        const newNotes = {...notes};
        delete newNotes[id]

        setNotes(newNotes);

        socket.emit("removeNote", {
            taskId,
            noteId: id
        });
    };

    return (
        <Draggable draggableId={taskId.toString()} index={index} key={taskId} isDragDisabled={!isDraggable}>
            {(provided, snapshot) => (
                <div
                    className={`animate__animated ${animation && !preventAnimation ? animation : ""} task ${snapshot.isDragging ? "task--dragging" : ""} ${targeted ? "task--targeted" : ""} ${closeToDeadline ? "task--close-to-deadline" : ""}${emoji ? " task--has-emoji" : ""}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{...provided.draggableProps.style}}>
                        <div className={`task__header`}>
                            <div className={`task__header-contents`}>
                                <div className="task__header-emoji-border-mask-right" />
                                <div className="task__header-emoji-border-mask-bottom" />
                                {emoji && <div className="task_emoji-badge">
                                    <div className="task_emoji-background" />
                                    <div className="task_emoji-container">
                                        <Emoji unified={emoji} size={width > 768 ? 30 : 24}/>
                                    </div>
                                </div>}
                                {targeted && <TargetIcon className="task__header-target-icon"/>}
                                <h4 className="task__title">{name}</h4>
                                <div className="task__label-container scroller">
                                    {labels && labels.map(label => (
                                        <div className="label-pill" key={`task-label__${label}`}>
                                            <p className="label-pill__text">{label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    <div className="task__body">
                        <div className="task__attributes">
                            <div className="task__attribute">
                                <p className="task__attribute-title">Difficulty</p>
                                <AttributeIndicator value={difficulty}
                                                    onAttributeChange={onAttributeChange(TaskConstants.ATTRIBUTE_DIFFICULTY)}/>
                            </div>
                                <div className="task__attribute">
                                    <p className="task__attribute-title">Importance</p>
                                    <AttributeIndicator value={importance} onAttributeChange={onAttributeChange(TaskConstants.ATTRIBUTE_IMPORTANCE)}/>
                                </div>
                            </div>
                            <div className="task__right-column">
                                <div className="task__notes-container">
                                    {notes &&
                                        <NotesContainer
                                            notes={notes}
                                            showInput={false}
                                            onNoteStatusChange={onNoteStatusChange}
                                            onNoteDeleted={onNoteDeleted}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`task__action-bar`}>
                            {deadlineDisplay &&
                                <Box className="task__action-deadline-container">
                                    <AlertIcon className={`task__action-deadline-icon`}/>
                                    <p className={`task__action-deadline-time`}>{deadlineDisplay}</p>
                                </Box>
                            }
                            {frequency &&
                                <Box className="task__action-recurring-container">
                                    <EventRepeatIcon className={`task__action-recurring-icon`}/>
                                    <p className={`task__action-recurring-info`}>{recurringLabelText}</p>
                                </Box>
                            }
                            <Box className="task__action-button-container">
                                <Button text="Cancel" color="red" icon="trash" collapsable={true} onClick={onCancelClick}/>
                                <Button text="Edit" color="orange" icon="edit" collapsable={true} onClick={onEditClick}/>
                                <Button text={targeted ? "Detarget" : "Target"}
                                        color="yellow"
                                        icon={targeted ? "detarget" : "target"}
                                        collapsable={true}
                                        onClick={onTargetClick}
                                />
                                <Button text="Complete" color="green" icon="tick" collapsable={true} onClick={onCompleteClick}/>
                            </Box>
                        </div>
                    </div>
            )}
        </Draggable>
    )
};

export default Task
