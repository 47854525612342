import {useSelector} from "react-redux";
import LabelFilterItem from "./LabelFilterItem";
import {Grid} from "@mui/material";
import {useCallback, useEffect} from "react";
import {dispatch} from "../../../redux/store";
import { setLabelFilters } from "../../../redux/actions/user";

const LabelFilters = ( ) => {
    const labels = useSelector(state => state.taskLabels);
    const labelFilters = useSelector(state => state.user.filters.labels);

    const onLabelFiltersChange = useCallback((newLabelFilters) => {
        dispatch(setLabelFilters(newLabelFilters));
        //TODO decide if this should be serverside
        //socket.emit("setFilters", { labels: newLabelFilters });
    }, []);

    // Setup labels filter state
    useEffect(() => {
        const tempLabelFilters = {}
        labels.forEach(label => {
            tempLabelFilters[label] = {
                include: labelFilters[label]?.include || false,
                exclude: labelFilters[label]?.exclude || false
            };
        });
        dispatch(setLabelFilters(tempLabelFilters));
    }, [labels]);

    const onFilterInclusionStatusChange = useCallback((label) => {
        return (filterType, status) => {
            const includedStatus = filterType === "include" ? status : filterType === "exclude" && status ? false : labelFilters[label]["include"];
            const excludedStatus = filterType === "exclude" ? status : filterType === "include" && status ? false : labelFilters[label]["exclude"];
            onLabelFiltersChange({
                ...labelFilters,
                [label]: {
                    "include": includedStatus,
                    "exclude": excludedStatus
                }
            });
        }
    }, [labels, labelFilters]);

    const setAllLabelFilters = useCallback((filterType, status) => {
        const tempLabelFilters = {};
        labels.forEach(label => {
            tempLabelFilters[label] = {
                include: filterType === "include" ? status : false,
                exclude: filterType === "exclude" ? status : false
            };
        });

        dispatch(setLabelFilters({
            ...tempLabelFilters
        }));
    }, [labels]);

    return (
        <div className="label-filters">
            <Grid container className="label-filters__header">
                <Grid item xs={6}>
                    <p className="label-filters__header-text">Label</p>
                </Grid>
                <Grid item xs={3}>
                    <p className="label-filters__header-text">Include</p>
                </Grid>
                <Grid item xs={3}>
                    <p className="label-filters__header-text">Exclude</p>
                </Grid>
            </Grid>
            <Grid container className="label-filters__label-container scroller">
                {
                    labels.map(label => {
                        if(labelFilters[label] !== undefined) {
                            return <LabelFilterItem labelText={label} filterLabelStatuses={labelFilters[label]} onFilterInclusionStatusChange={onFilterInclusionStatusChange(label)} key={label}/>
                        }
                    })
                }
            </Grid>
            <Grid container className="label-filters__footer">
                <Grid item xs={12}>
                    <p className="label-filters__clear-filters" onClick={() => {
                        setAllLabelFilters("include", false);
                        setAllLabelFilters("exclude", false);
                    }}>
                        Clear Filters
                    </p>
                </Grid>
            </Grid>
        </div>
    )
}

export default LabelFilters;
