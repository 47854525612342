import React from "react";
import EditButton from "../input/button/EditButton";
import {ReactComponent as CloseIcon} from "../../assets/icons/close_black_24dp.svg";

const LabelContainer = ({ selectedLabels, onChangeEditLabelPanelVisibility, editPanelIsOpen }) => {
    return (
        <div className="label-container">
            <div className="label-container_header">
                <p className="label-container_label-text">Labels</p>
                {!editPanelIsOpen ?
                    <EditButton
                        onClick={() => onChangeEditLabelPanelVisibility(true)}
                    />
                    :
                    <CloseIcon
                        className="label-container_close-panel-button"
                        onClick={() => onChangeEditLabelPanelVisibility(false)}
                    />
                }
            </div>
            <div className="label-pill-container">
                {selectedLabels && selectedLabels.map(selectedLabel => (
                    <div className="label-pill" key={selectedLabel}>
                        <p className="label-pill__text">
                            {selectedLabel}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default LabelContainer