import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {setOverlayVisible} from "../../redux/actions/overlay";
import BurgerMenu from "../menu";
import Logo from "../../assets/mindstack-logo.png";

const Header = () => {
    const dispatch = useDispatch();

    const onLogoClicked = () => {
        dispatch(setOverlayVisible({visible: false}))
    }

    return (
        <div className="header">
            <BurgerMenu />
            <Link to="/" onClick={onLogoClicked} className="header_logo-container">
                <img className="header_logo-image" src={Logo} />
                <h1 className="header__title">Mindstack</h1>
            </Link>
            <div />
        </div>
    )
};

export default Header;