import {useState} from "react";
import {Card, Grid, Tab} from "@mui/material";
import SessionAnalytics from "./SessionAnalytics";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import UserAnalytics from "./UserAnalytics";

const AdminTabs = () => {
    const [activeTab, setActiveTab] = useState("1");
    return (
        <Grid item xs={12}>
            <Card>
                <TabContext value={activeTab}>
                    <TabList onChange={(e, newValue) => setActiveTab(newValue)}>
                        <Tab label="Sessions" value={"1"}/>
                        <Tab label="Users" value={"2"}/>
                    </TabList>
                    <TabPanel value="1">
                        <SessionAnalytics />
                    </TabPanel>
                    <TabPanel value="2">
                        <UserAnalytics />
                    </TabPanel>
                </TabContext>
            </Card>
        </Grid>
    )
};

export default AdminTabs;