import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const TaskDeadlines = () => {
    return (
        <Page className="about-page task-deadlines-page" pageKey="task-deadlines">
            <AboutTemplate>
                <Typography variant="h1">Task deadlines</Typography>
                <Section>
                    <Typography variant="body1">Sometimes a task has a certain date it must be completed by.
                        You can set a deadline for a task which will let you know how long you have left to complete the task, and prioritise it accordingly.</Typography>
                    <ResponsiveImage imageName="deadline-task" pageKey="deadlines" size="large" />
                    <Typography variant="body1">Task deadlines are taken into consideration when automatically ordering your tasks. As a task approaches its deadline it is weighted higher, and will bubble up your task list.</Typography>
                    <Typography variant="body1">When there is less than 10% of the time remaining before a task's due date, or it is already overdue, the task's header will turn red to really highlight that you need to complete this task. </Typography>
                    <ResponsiveImage imageName="overdue" pageKey="deadlines" size="large" />
                </Section>
                <Section title="Setting a deadline">
                    <Typography variant="body1">You can set a deadline for a task through either the <Link href="/about/features/tasks/create-a-task" variant="body1">"Create Task"</Link> or "Edit Task" pages.</Typography>
                    <ImageAndDescription imageName="deadline-checkbox" pageKey="deadlines" description="Click the checkbox next to the 'Deadline' input to add a deadline to your task" />
                    <Typography variant="body1">The task's deadline will default to the next hour, but you can click the input to open a date picker and select your task's deadline</Typography>
                    <ResponsiveImage imageName="date-picker" pageKey="deadlines" size="small" />
                </Section>
                <Section title="Removing a deadline">
                    <ImageAndDescription imageName="remove-deadline" pageKey="deadlines" description="Click the checkbox next to the 'Deadline' input to remove the deadline from your task" />
                    <Typography variant="body1">A task may either have a deadline, or be a recurring task. Not both. If you enable recur whilst a task has a deadline set, the deadline will be removed.</Typography>
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default TaskDeadlines;