import React from "react";
import {ReactComponent as TooltipIcon} from "../../assets/icons/help-circle.svg";


const Tooltip = ({text}) => {
    return (
        <div className="tooltip">
            <TooltipIcon className="tooltip__icon"/>
            <div className="tooltip__popup">
                <p className="tooltip__text">{text}</p>
            </div>
        </div>
    )
};

export default Tooltip;
