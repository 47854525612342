import useWindowDimensions from "../../../helpers/window";
import { stack as Menu } from 'react-burger-menu'
import {Typography} from "@mui/material";
import AboutMenuItemList from "./components/AboutMenuItemList";

const AboutMenu = () => {
    const { width } = useWindowDimensions();
    return (
        <div className={"about-menu"}>
        {
            width < 1280 ?
                <div className={"about-menu_mobile"}>
                    <Menu width={330}>
                        <AboutMenuItemList />
                    </Menu>
                    <Typography sx={{ml: "48px", mb: { xs: 0 }}} variant="headerTitle">Menu</Typography>
                </div>
                :
                <div className={"about-menu_desktop"}>
                    <AboutMenuItemList />
                </div>
            }
        </div>
    )
};

export default AboutMenu;