import { combineReducers } from 'redux'

import user from "./user";
import overlay from "./overlay";
import taskLabels from "./taskLabels";
import tasks from "./tasks";
import definitions from "./definitions";
import banners from "./banners";
import recurringTasks from "./recurringTasks";
import admin from "./admin";
import about from "./about";
import session from "./session";

export default combineReducers({
    user,
    overlay,
    taskLabels,
    tasks,
    definitions,
    banners,
    recurringTasks,
    admin,
    about,
    session
});
