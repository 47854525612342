import React from "react";
import {ReactComponent as ShuffleIcon} from "../../../assets/icons/refresh-cw.svg";

const SnuffleButton = ({ onClick }) => {
    return (
        <div className="shuffle-button  animate__animated animate__heartBeat animate__delay-2s" onClick={onClick}>
            <ShuffleIcon />
        </div>
    )
};

export default SnuffleButton