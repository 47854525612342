import {Grid, Typography} from "@mui/material";
import ResponsiveImage from "./ResponsiveImage";

const ImageAndDescription = ({ imageName, pageKey, description }) => {
    return (
        <Grid container className="image-and-description">
            <Grid item xs={12} lg={6} className="image-and-description_column">
                <div className="image-and-description_image-container">
                    <ResponsiveImage imageName={imageName} pageKey={pageKey}/>
                </div>
            </Grid>
            <Grid item xs={12} lg={6} className="image-and-description_column">
                <Typography variant="body1">{description}</Typography>
            </Grid>
        </Grid>
    );
}

export default ImageAndDescription;