import React from "react";
import {ReactComponent as PlusIcon} from "../../../assets/icons/add_black_24dp.svg";
import {ReactComponent as MinusIcon} from "../../../assets/icons/remove_black_24dp.svg";

const TickerButton = ({ onClick, direction }) => {
    return (
        <div className="ticker-button" onClick={onClick}>
            {direction === "up" ?
                <PlusIcon className="ticker-button__icon" />
                :
                <MinusIcon className="ticker-button__icon"/>
            }
        </div>
    )
};

export default TickerButton
