import { SET_RECURRING_TASKS, REMOVE_RECURRING_TASK, UPDATE_TASK_FREQUENCY } from "../constants/recurringTasks";

export const setRecurringTasks = (recurringTasks) => {
    return {
        type: SET_RECURRING_TASKS,
        recurringTasks
    }
}

export const removeRecurringTask = (taskId) => {
    return {
        type: REMOVE_RECURRING_TASK,
        taskId
    }
}

export const updateTaskFrequency = (taskId, frequency) => {
    return {
        type: UPDATE_TASK_FREQUENCY,
        taskId,
        frequency
    }
}