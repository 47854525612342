import {
    CreateTaskPage,
    EditTaskPage,
    HomePage,
    LoginPage,
    TasksPage,
    AboutPage,
    AdminPage,
    FeedbackPage,
    RecurringTasksPage
} from "../pages";
import {getAboutRoutes} from "./about-routes";

const routes = [
    {
        path: "/",
        element: <HomePage/>
    },
    {
        path: "/login",
        element: <LoginPage/>
    },
    {
        path: "/tasks",
        element: <TasksPage/>
    },
    {
        path: "/create-task",
        element: <CreateTaskPage/>
    },
    {
        path: "/edit-task",
        element: <EditTaskPage/>
    },
    {
        path: "/about",
        element: <AboutPage/>
    },
    {
        path: "/admin",
        element: <AdminPage />
    },
    {
        path: "/feedback",
        element: <FeedbackPage />
    },
    {
        path: "/recurring-tasks",
        element: <RecurringTasksPage />
    }
]

export const getRoutes = () => {
    return [...routes, ...getAboutRoutes()];
}