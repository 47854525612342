import {Checkbox, Grid} from "@mui/material";

const LabelFilterItem = ({ labelText, filterLabelStatuses, onFilterInclusionStatusChange }) => {

    const onFilterIncluded = (e) => {
        const checked = e.target.checked;
        onFilterInclusionStatusChange("include", checked);
    }

    const onFilterExcluded = (e) => {
        const checked = e.target.checked;
        onFilterInclusionStatusChange("exclude", checked);
    }

    return (
        <Grid container className={"label-filter-item"} key={`label-${labelText}`}>
            <Grid item xs={6}>
                <p className="label-filter-item__label-text">{labelText}</p>
            </Grid>

            <Grid item xs={3}>
                <Checkbox size={"large"} checked={filterLabelStatuses["include"] ?? false} onChange={onFilterIncluded} />
            </Grid>
            <Grid item xs={3}>
                <Checkbox size={"large"} checked={filterLabelStatuses["exclude"] ?? false} onChange={onFilterExcluded} />
            </Grid>
        </Grid>
    );
}

export default LabelFilterItem;
