import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import DeveloperNotes from "../../components/about/DeveloperNotes";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const Labels = () => {
    return (
        <Page className="about-page labels-page" pageKey="labels">
            <AboutTemplate>
                <Typography variant="h1">Labels</Typography>
                <Section>
                    <Typography variant="body1">Labels are a way to categorize your tasks. You can assign one or more labels to a task to help you organize your tasks by grouping similar tasks together.</Typography>
                    <Typography variant="body1">You can use one of Mindstack's in-built labels, or create your own.</Typography>
                    <ResponsiveImage imageName="task" pageKey="labels" size="large" />
                </Section>
                <Section>
                    <Typography variant="h2">Setting a label</Typography>
                    <Typography variant="body1">You can add labels to tasks through either the <Link href="/about/features/tasks/create-a-task" variant="body1">"Create Task"</Link> or "Edit Task" pages</Typography>
                    <Typography variant="body1">Click the pencil button next to the label list to open the label selector.</Typography>
                    <ImageAndDescription imageName="open-labels" pageKey="labels" description="Click the pencil button to open the label selector" />
                    <Typography variant="body1">Select a label from the dropdown to assign the label to the task.</Typography>
                    <ResponsiveImage imageName="dropdown" pageKey="labels" size="large" />
                    <Typography variant="body1">You can select multiple labels per task.</Typography>
                    <ResponsiveImage imageName="multiple-labels" pageKey="labels" size="large" />
                </Section>
                <Section>
                    <Typography variant="h3">Creating your own labels</Typography>
                    <Typography variant="body1">At the bottom of the label selector, click the "+" button to add a new label.</Typography>
                    <Typography variant="body1">Type in the new label's name, and click the tick button to create it.</Typography>
                    <ResponsiveImage imageName="new-label" pageKey="labels" size="large" />
                </Section>
                <Section>
                    <Typography variant="h3">Deleting a label</Typography>
                    <ImageAndDescription imageName="delete-label" pageKey="labels" description="Click the dustbin icon to delete a label and remove the label from all tasks" />
                    <Typography variant="body1">You will see a modal to ensure that you intended to delete the label before removing it.</Typography>
                    <ResponsiveImage imageName="delete-modal" pageKey="labels" size="large" />
                </Section>
                <Section>
                    <Typography variant="h2">Filtering using labels</Typography>
                    <Typography variant="body1">Once labels have been added to your tasks, you can <Link href="/about/features/task-filtering" variant="body1">filter your tasks</Link> to show/hide tasks based on their label.</Typography>
                </Section>

                <DeveloperNotes>
                    <Typography variant="body1">I've found labels to be a great way to choose tasks based on the time I have and what I want to do.</Typography>
                    <Typography variant="body1">For example, if I have an hour after work to sort out some chores, I can tell Mindstack "just show me my chores" to see a list of all of my chores.</Typography>
                    <Typography variant="body1">I fancy hanging out with some friends I can filter by "social" to see all of my social tasks and help me make plans.</Typography>
                </DeveloperNotes>
            </AboutTemplate>
        </Page>
    );
}

export default Labels;