import {Checkbox} from "@mui/material";
import {ReactComponent as RemoveIcon} from "../../../../assets/icons/close_black_24dp.svg";
import React from "react";
import useWindowDimensions from "../../../../helpers/window";
import { useDispatch } from "react-redux";
import {setOverlayVisible} from "../../../../redux/actions/overlay";

const Note  = ({ id, text, completed, onNoteStatusChange, onNoteDeleted, mock }) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const isMobile = width < 768;

    const onCheckboxClicked  = ()  => {
        if(mock) {
            return;
        }
        onNoteStatusChange({id, status: !completed});
    }

    const onRemoveClicked = () => {
        if(mock) {
            onNoteDeleted(id);
            return;
        }

        const onConfirmCallback = () => {
            onNoteDeleted(id);
            dispatch(setOverlayVisible({visible: false}))
        }

        dispatch(setOverlayVisible({
            visible: true,
            overlayType: "confirm",
            confirmationText: "Are you sure you want to remove this note?",
            confirmCallback: onConfirmCallback
        }));
    }

    return (
        <div className="note" key={id}>
            <a className="note__details-container" onClick={onCheckboxClicked}>
                <Checkbox checked={completed} className="note__checkbox" size={isMobile ? "small" : "medium"}/>
                <p className={`note__text ${completed ? "note__text--completed" : ""}`}>{text}</p>
            </a>
            <RemoveIcon className="note__remove-button" onClick={onRemoveClicked}/>
        </div>
    )
}

export default Note;
