const palette = {
    white: {
        main: "#FFFFFF"
    },
    icon: {
        main: "#333333"
    },
    primary: {
        main: "#197B68",
        dark: "#004A3C"
    },
    orange: {
        main: "#c26229",
        dark: "#a04b1b"
    },
    yellow: {
        main: "#ffa600",
        dark: "#df9600"
    },
    green: {
        main: "#429254",
        dark: "#2d6b3e"
    },
    blue: {
        main: "#276580",
        dark: "#1b4a63"
    },
    purple: {
        main: "#813773",
        dark: "#6A205C"
    },
    link: {
        main: "#0099cc",
        dark: "#0077aa"
    },
}

export default palette;