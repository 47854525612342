import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect} from "react";
import {dispatch} from "../../redux/store";
import {setActiveSessions, setSessionHistory, setSessions} from "../../redux/actions/admin";
import {useSelector} from "react-redux";
import {child, get, getDatabase, off, ref, query, limitToLast, onValue} from "firebase/database";

const SessionAnalytics = () => {
    const { analytics } = useSelector(state => state.admin);
    const database = getDatabase();
    const { sessions, activeSessions, sessionHistory } = analytics;

    const sessionEntries = Object.entries(sessions ?? {});

    const sessionHistoryList = Object.values(sessionHistory).map((session) => ({
        name: session.name,
        connectionTime: new Date(session.connectionTime).toLocaleString(),
        uid: session.uid
    }));

    useEffect(() => {
        if(!database) return;
        const dbRef = ref((getDatabase()));

        get(child(dbRef, "admin/analytics/activeSessions")).then(snapshot => {
            const activeSessions = snapshot.val();
            dispatch(setActiveSessions(activeSessions))
        });
        get(child(dbRef, "admin/analytics/sessions")).then(snapshot => {
            const sessions = snapshot.val();
            dispatch(setSessions(sessions))
        });

        const sessionHistoryQuery = query(child(dbRef, "admin/analytics/sessionHistory"), limitToLast(10))
        onValue(sessionHistoryQuery, snapshot => {
            const sessionHistory = snapshot.val();
            dispatch(setSessionHistory(sessionHistory));
        });

        return () => {
            off(dbRef);
        }
    }, [database]);

    return (
        <div className="session-analytics">
            <h2>Sessions ({activeSessions})</h2>
            {activeSessions > 0 &&
                <div className={"session-analytics__session-list scroller"}>
                    <div>
                        {sessionEntries.map(([uid, sessionsForUserMap], index) => {
                            const sessionsForUser = Object.values(sessionsForUserMap);
                            return (
                            <Accordion key={uid} className={"session-analytics__user-entry"}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {sessionsForUser[0].name ?
                                        <h4 className="session-analytics__session-username">{sessionsForUser[0].name}</h4>
                                        :
                                        <h4 className="session-analytics__session-username">{sessionsForUser[0].email}</h4>}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>UID: <span>{uid}</span></p>
                                    <p>Name: {sessionsForUser[0].name}</p>
                                    <p>Email: {sessionsForUser[0].email}</p>
                                    <h4>Sessions for user</h4>
                                    <div>
                                        {sessionsForUser.map((session, index) => (
                                            <Accordion key={session.socketId} className={"session-analytics__user-session-entry"}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <h5 className="session-analytics__session-title">{session.socketId}</h5>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <p>Socket ID: <span>{session.socketId}</span></p>
                                                    <p>Connected at: <span>{session.connectionTime}</span></p>
                                                    <p>User Agent: <span>{session.userAgent}</span></p>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            )
                        })}
                    </div>
            </div>}

            <h2>Session History</h2>
            <div className={"session-analytics__session-history"}>
                {sessionHistoryList.reverse().map((session, index) => (
                    <div key={index} className={"session-analytics__session-history-entry"}>
                        <p className="session-analytics__session-history-name">{session.name} (UID: {session.uid})</p>
                        <p className="session-analytics__session-history-time">Connected at: {session.connectionTime}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SessionAnalytics;