import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DeveloperNotes = ({ children }) => {
    return (
        <Accordion className="developer-notes">
            <AccordionSummary className="developer-notes_header" expandIcon={<ArrowDropDownIcon stroke="white" color="white" sx={{ width: 36, height: 36 }}/>}>
                <Typography variant="h3">Developer Notes</Typography>
            </AccordionSummary>
            <AccordionDetails className="developer-notes_content">
                { children }
            </AccordionDetails>
        </Accordion>
    )
}

export default DeveloperNotes;