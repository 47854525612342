import React from "react";
import { useSelector } from "react-redux";
import ConfirmOverlay from "./ConfirmOverlay";
import GetTaskOverlay from "./GetTask";

const Overlay = () => {
    const { visible, overlayType, overlayMode, ...rest } = useSelector(state => state.overlay);

    return (
        <div className={`overlay animate__animated animate__fadeIn  animate__fast${visible ? " overlay--visible" : ""}${visible ? ` overlay--${overlayType}` : ""}`}>
            {visible &&
                <>
                    {overlayType === "confirm" && <ConfirmOverlay {...rest} />}
                    {overlayType === "getTask" && <GetTaskOverlay />}
                </>
            }
        </div>
    )
}

export default Overlay;
