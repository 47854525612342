import {Box, Grid} from "@mui/material";
import MoodSelectionImage from "../../assets/mood-selection.png";
import FeelingBadImage from "../../assets/feeling-bad.png";
import FeelingGoodImage from "../../assets/feeling-good.png";
import TargetsImage from "../../assets/targets.png";
import TargetsMobileImage from "../../assets/targets_mobile.png";
import useWindowDimensions from "../../helpers/window";

const TaskPrioritization = () => {
    const { width } = useWindowDimensions();

    return (
        <Box className="task-prioritization">
            <Box className="task-prioritization-container">
                <Box className="task-prioritization_mood-selection">
                    <Grid container className="task-prioritization_mood-selection-inner-container">
                        <Grid item xs={12} md={1} />
                        <Grid item xs={12} md={6} className="home-page_feature-image-container">
                            <img src={MoodSelectionImage} className="home-page_feature-image task-prioritization_mood-selection-image"/>
                        </Grid>
                        <Grid item xs={12} md={5}  className="home-page_feature-text-container">
                            <h2 className="home-page_feature-header">Indicate your state of mind</h2>
                            <p className="home-page_feature-text">Tell Mindstack how you are feeling, so it can recommend tasks based on your current mood</p>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="task-prioritization_suggested-tasks">
                    <Grid container className="task-prioritization_suggested-tasks-inner-container">
                        <Grid item xs={12}  className="home-page_feature-text-container">
                            <h2 className="home-page_feature-header">Suggested tasks</h2>
                            <p className="home-page_feature-text">Get task recommendations, based on your current mood</p>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center"}}>
                            <Box className="home-page_feature-image-container home-page_feature-image-container--top">
                                <img src={FeelingGoodImage} className="home-page_feature-image"/>
                                <h2 className="home-page_feature-image-label-title">Feeling Good?</h2>
                                <p className="home-page_feature-image-label-text">More difficult tasks are recommended, to make the most of your energy</p>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center"}}>
                            <Box className="home-page_feature-image-container">
                                <img src={FeelingBadImage} className="home-page_feature-image"/>
                                <h2 className="home-page_feature-image-label-title">Not feeling great?</h2>
                                <p className="home-page_feature-image-label-text">Get easier, more enjoyable task recommendations to help you stay productive when you are feeling low</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="task-prioritization_targeted-tasks">
                    <Grid container className="task-prioritization_targeted-tasks-inner-container">
                        <Grid item xs={12}  className="home-page_feature-text-container">
                            <h2 className="home-page_feature-header">Targeted tasks</h2>
                            <p className="home-page_feature-text">Target your tasks to keep your mind focused on what needs doing first</p>
                        </Grid>
                        <Grid item xs={12} className="home-page_feature-image-container">
                            <img src={width > 768 ? TargetsImage : TargetsMobileImage} className="home-page_feature-image"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default TaskPrioritization;