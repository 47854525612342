import AboutMenu from "./menu";

const AboutTemplate = ({ children }) => {
    return (
        <div className={"about-page__container"}>
            <div className={"about-page__menu-container"}>
                <AboutMenu />
            </div>

            <div className={"about-page__content-container"}>
                <div className={"about-page__content"}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AboutTemplate;