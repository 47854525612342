import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Grid, Typography} from "@mui/material";
import {
    Link,
} from '@mui/material';

const Features = () => {
    return (
        <Page className="about-page features-page" pageKey="features">
            <AboutTemplate>
                <Typography variant="h1">Features</Typography>
                <Typography variant="body1">Here you can find descriptions of the various features you will find in Mindstack.</Typography>
                <Typography variant="body1">Select a feature to learn more about it.</Typography>
                <Grid container />
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/tasks">Tasks</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/stacks">Stacks</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/targets">Targets</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/mindset">Mindset</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/task-suggestions">Task Suggestions</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/task-search">Task Search</Link>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2}}>
                    <Link href="features/task-filtering">Task Filtering</Link>
                </Grid>
            </AboutTemplate>
        </Page>
    );
}

export default Features;