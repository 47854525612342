import AboutTemplate from "../../components/about/AboutTemplate";
import {Typography} from "@mui/material";
import Page from "../Page";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";
import DeveloperNotes from "../../components/about/DeveloperNotes";

const TaskTargeting = () => {
    return (
        <Page className="about-page task-targetting-page" pageKey="task-targetting">
            <AboutTemplate>
                <Typography variant="h1">Task Targeting</Typography>
                <Section>
                    <Typography variant="body1">Task targeting is a way to bring a task into focus and commit to completing it.</Typography>
                    <Typography variant="body1">Targeting a task separates the task from the rest of your stack by adding a yellow crosshair to the task header and moving the task to the top of your stack (on mobile) or a separate stack to the left of the screen (on desktop).</Typography>
                    <ResponsiveImage imageName="targeted-task" pageKey="targets" size="large" />
                </Section>
                <Section title="Setting a target">
                    <ImageAndDescription imageName="target-toggle" pageKey="targets" description="You can target a task by clicking the 'Target' toggle button when creating or editing a task or by clicking the target icon on the task in your task list." />
                    <Typography variant="body1">Once a task is targeted, the task will be moved to the top of your stack (on mobile) or a separate stack to the left of the screen (on desktop).</Typography>
                </Section>
                <Section title="Removing a target">
                    <ImageAndDescription imageName="remove-target" pageKey="targets" description="You can remove a task's target by clicking the target icon on the task in your task list or toggle the 'Target' button off on the edit task page." />
                    <Typography variant="body1">The task will be moved back to its original position in your stack.</Typography>
                </Section>
                <DeveloperNotes>
                    <Typography variant="body1">I've found the Task Targeting feature to be most useful when you are ready to commit to completing a task.</Typography>
                    <Typography variant="body1">Use it to separate imminent tasks from the rest of your stack, so you can give them the focus they deserve.</Typography>
                    <Typography variant="body1">However, be careful not to over-commit to too many tasks.</Typography>
                    <Typography variant="body1">At times I would saturate my targets with too many tasks, which can make it hard to get a true picture of what you actually intend to do.</Typography>
                    <Typography variant="body1">Only target tasks when you really are ready to focus on them.</Typography>
                </DeveloperNotes>
            </AboutTemplate>
        </Page>
    )
}

export default TaskTargeting;