import {SET_INITIAL_LOAD} from "../constants/session";

const initialState = {
    initialLoad: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_LOAD:
            return {
                ...state,
                initialLoad: action.state
            }
        default:
            return state
    }
}