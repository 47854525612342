import {SET_LABEL_FILTERS, SET_MINDSET, SET_USER, SET_USER_AUTHENTICATED} from "../constants/user";

const initialValue = {
    activeUser: null,
    authenticated: false,
    mindset: {
        mood: 5,
        energy: 5
    },
    filters: {
        labels: {}
    }
};

export default (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                activeUser: action.user
            };
        case SET_USER_AUTHENTICATED: {
            return {
                ...state,
                authenticated: action.status
            }
        }
        case SET_MINDSET: {
            return {
                ...state,
                mindset: action.mindset
            }
        }
        case SET_LABEL_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    labels: {
                        ...state.filters.labels,
                        ...action.labelFilters
                    }
                }
            }
        }
        default:
            return state
    }
}
