import {PushNotifications} from "@capacitor/push-notifications";

export const initialisePushNotifications = () => {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
        } else {
            // Show some error
        }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token) => {
            //TODO This is where we would send the token to our server
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error) => {
            alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
            //TODO Any custom logic to handle the notification
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
            //TODO Any custom login for when a notification from the backend is tapped
        }
    );
}