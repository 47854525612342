import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const TaskSuggestions = () => {
    return (
        <Page className="about-page task-suggestions-page" pageKey="task-suggestions">
            <AboutTemplate>
                <Typography variant="h1">Task suggestions</Typography>
                <Section>
                    <Typography variant="body1">Task suggestions are a way to get ideas for task to focus on based on your current <Link href="/about/features/mindset" variant="body1">mindset</Link></Typography>
                    <ResponsiveImage pageKey="taskSuggestions" imageName="task-suggestions" size="large" />
                </Section>
                <Section title="See your suggested tasks">
                    <ImageAndDescription imageName="suggestion-icon" pageKey="taskSuggestions" description="Click the purple smiley face icon at the bottom right of the screen to open the mindset and suggested tasks modal." />

                    <Typography variant="body1">Once you have set your mindset, you will see a list of tasks that are recommended for you based on your current mood and energy levels.</Typography>

                    <Typography variant="body1">If you have low energy, easier tasks will be suggested. If you have a lot of energy, harder tasks will be suggested</Typography>
                    <Typography variant="body1">If you are in a bad mood, more fun tasks will be suggested. If you are in a good mood, less fun tasks will be suggested.</Typography>
                </Section>
                <Section title="Targeting a suggested task">
                    <Typography variant="body1">You can <Link href="/about/features/task-targeting" variant="body1">target</Link> a suggested task by clicking the task and then clicking the "Target" icon at the bottom of the modal.</Typography>
                    <ResponsiveImage pageKey="taskSuggestions" imageName="target-button" size="medium" />
                </Section>
                <Section title="Shuffle suggested tasks">
                    <Typography variant="body1">If you don't like the tasks that are suggested, you can shuffle the list to get a new set of tasks.</Typography>
                    <Typography variant="body1">Click the "Shuffle" button at the bottom of the modal to get a new set of tasks.</Typography>
                    <ResponsiveImage pageKey="taskSuggestions" imageName="shuffle-button" size="medium" />
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default TaskSuggestions;