import {Grid} from "@mui/material";
import DeadlinesImage from "../../assets/deadlines.png";
import DeadlinesMobileImage from "../../assets/deadlines_mobile.png";
import useWindowDimensions from "../../helpers/window";

const Deadlines =  () => {
    const { width } = useWindowDimensions();

    return (
        <div className="deadlines">
            <Grid container className="deadlines-container">
                <Grid item xs={12} md={6} className="home-page_feature-image-container">
                    <img src={width > 1400 ? DeadlinesImage : DeadlinesMobileImage} className="home-page_feature-image"/>
                </Grid>
                <Grid item xs={12} md={4}  className="home-page_feature-text-container">
                    <h2 className="home-page_feature-header">Task deadlines</h2>
                    <p className="home-page_feature-text">Add deadlines to keep track of your time sensitive tasks</p>
                </Grid>
                <Grid item xs={12} md={2} />
            </Grid>
        </div>
    )
}

export default Deadlines;