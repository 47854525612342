import Ticker from "../../ticker";
import {Grid} from "@mui/material";
import React from "react";

const AttributesContainer = ({ attributes, onAttributeChange }) => {

    return (
        <Grid container className={"attribute-container"}>
            <Grid item xs={6} md={3} sx={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <Ticker
                    name="Difficulty"
                    value={attributes["difficulty"]}
                    maxValue={10}
                    onValueChange={onAttributeChange("difficulty")}
                />
            </Grid>
            <Grid item xs={6} md={3} sx={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <Ticker
                    name="Importance"
                    value={attributes["importance"]}
                    maxValue={10}
                    onValueChange={onAttributeChange("importance")}
                />
            </Grid>
            <Grid item xs={6} md={3} sx={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <Ticker
                    name="Fun"
                    value={attributes["fun"]}
                    maxValue={10}
                    onValueChange={onAttributeChange("fun")}
                    invertColours
                />
            </Grid>
        </Grid>
    )
}

export default AttributesContainer;
