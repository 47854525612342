import React, { useState } from "react";
import {ReactComponent as FilterIcon} from "../../assets/icons/filter_alt_black_24dp.svg";
import TaskFilterMenu from "./filter/TaskFilterMenu";

const TaskListHeader = ({ title }) => {
    const [showTaskFilterMenu, setShowTaskFilterMenu] = useState(false);
    return (
        <div className="task-list-header">
            <div />
            <h1 className="task-list-header__title">{title}</h1>
            <div className="task-list-header__controls-container">
                <div className="task-list-header__filter-button" onClick={() => setShowTaskFilterMenu(!showTaskFilterMenu)}>
                    <p className="task-list-header__filter-text">Filter</p>
                    <FilterIcon className="task-list-header__filter-icon"/>
                </div>

                {showTaskFilterMenu &&
                    <TaskFilterMenu onClose={() => {setShowTaskFilterMenu(false)}}/>
                }
            </div>
        </div>
    )
};

export default TaskListHeader
