import store, {dispatch} from "../../../redux/store";
import {setTaskCreationTime, setTaskPreventAnimation, setTaskTrueId} from "../../../redux/actions/tasks";
import {Capacitor} from "@capacitor/core";
import scheduleNotificationsForTask from "../../../notifications/scheduleNotificationsForTask";


export default ({clientTaskId, taskId, created}) => {
    setTimeout(() => {
        //TODO Delete if no problems
        //dispatch(setTaskPreventAnimation({taskId: clientTaskId, value: true})); //Otherwise, causes janky fading in/out
        dispatch(setTaskTrueId(clientTaskId, taskId));
        dispatch(setTaskCreationTime({taskId, created }));

        if(Capacitor.isNativePlatform()) {
            scheduleNotificationsForTask(taskId, created);
        }
    }, 500)
}