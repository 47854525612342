import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {ReactComponent as CheckIcon} from "../../assets/icons/check_black_24dp.svg";
import {ReactComponent as RemoveIcon} from "../../assets/icons/close_black_24dp.svg";
import {ReactComponent as TrashIcon} from "../../assets/icons/trash.svg";
import socket from "../../socket";
import {setOverlayVisible} from "../../redux/actions/overlay";

const LabelList = ({ selectedLabels, onSelectedLabelsChange, onLabelRemoved }) => {
    const labels = useSelector(state => state.taskLabels);
    const dispatch = useDispatch();

    return (
        <div className="label-list">
            {labels.map(label => {
                const onClick = () => {
                    const newLabelsArray = Array.from(selectedLabels);
                    if(newLabelsArray.includes(label)) {
                        newLabelsArray.splice(selectedLabels.indexOf(label), 1)
                    } else {
                        newLabelsArray.push(label);
                    }

                    onSelectedLabelsChange(newLabelsArray)
                };

                const onTrashCanClick = () => {
                    const onConfirmCallback = () => {
                        setTimeout(() => {
                            socket.emit("removeLabel", {
                                label
                            }, onLabelRemoved);
                        }, 400);
                        dispatch(setOverlayVisible({visible: false}))
                    }

                    dispatch(setOverlayVisible({
                        visible: true,
                        overlayType: "confirm",
                        confirmationText: "Are you sure you want to remove this label?",
                        confirmCallback: onConfirmCallback
                    }));
                }

                return (
                    <a className={`label-list__label${selectedLabels.includes(label) ? " label-list__label--selected" : ""}`}
                         onClick={onClick}
                         key={label}
                    >
                        <CheckIcon className="label-list__label-selected-button"/>
                        <p className="label-list__label-text">{label}</p>
                        <TrashIcon className="label-list__label-trash-button" onClick={onTrashCanClick}/>
                        <RemoveIcon className="label-list__label-remove-button"/>
                    </a>
                )
            })}
        </div>
    )
};

export default LabelList;