import React, {useCallback, useState} from "react";
import { Slider } from "@mui/material";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';

const moodTextMap = {
    1: "Awful",
    2: "Very bad",
    3: "Bad",
    4: "Pretty bad",
    5: "Okay",
    6: "Pretty good",
    7: "Good",
    8: "Very good",
    9: "Great",
    10: "Amazing"
}

const energyTextMap = {
    1: "Exhausted",
    2: "Very Tired",
    3: "Tired",
    4: "A little tired",
    5: "Okay",
    6: "Pretty active",
    7: "Active",
    8: "Very active",
    9: "Zesty",
    10: "Invigorated"
}

const MindsetSelection = ({ onUpdateMindset, initialMindset }) => {
    const [mood, setMood] = useState(initialMindset.mood);
    const [energy, setEnergy] = useState(initialMindset.energy);

    const onMoodChange = useCallback((event, value) => {
        if(value === mood) return;

        setMood(value);
        onUpdateMindset({
            mood: value,
            energy
        });
    }, [mood, energy]);

    const onEnergyChange = useCallback((event, value) => {
        if(value === energy) return;

        setEnergy(value);
        onUpdateMindset({
            mood,
            energy: value
        });
    }, [mood, energy]);

    return (
        <div className="mood-selection">
            <h2 className="mood-selection_title">How are you feeling?</h2>
            <div className="mood-selection_inputs-container">
                <div className="mood-selection_mood-container">
                    <div className="mood-selection_mood-input-container">
                        <SentimentVeryDissatisfiedIcon className="mood-selection_slider-icon"/>
                        <Slider
                            aria-label="mood-slider"
                            defaultValue={initialMindset.mood}
                            step={1}
                            marks
                            min={1}
                            max={10}
                            onChangeCommitted={onMoodChange}
                        />
                        <SentimentSatisfiedAltIcon className="mood-selection_slider-icon"/>
                    </div>
                    <h3 className="mood-selection_input-text">{moodTextMap[mood]}</h3>
                </div>
                <div className="mood-selection_energy-container">
                    <div className="mood-selection_energy-input-container">
                        <Battery1BarIcon className="mood-selection_slider-icon"/>
                        <Slider
                            aria-label="energy-slider"
                            defaultValue={initialMindset.energy}
                            step={1}
                            marks
                            min={1}
                            max={10}
                            onChangeCommitted={onEnergyChange}
                        />
                        <Battery6BarIcon className="mood-selection_slider-icon"/>
                    </div>
                    <h3 className="mood-selection_input-text">{energyTextMap[energy]}</h3>
                </div>
            </div>
        </div>
    )
}

export default MindsetSelection;
