import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { Droppable } from 'react-beautiful-dnd';
import {smartSortTasks} from "../../helpers/list";
import Fuse from "fuse.js";

import Task from "./taskCard/Task";
import useWindowDimensions from "../../helpers/window";

const List = ({ tasks, listId, searchTerm }) => {
    const attributeDefinitions = useSelector(state => state.definitions.attributes);
    const filters = useSelector(state => state.user.filters);
    const [displayTasks, setDisplayTasks] = useState(tasks);
    const [forceSort, setForceSort] = useState(true); //Used to trigger re-sort if something changes about a task that may affect it's order

    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowDimensions();
    useEffect(() => {
        if(width <= 768 ) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width]);

    //This effect asks the list to re-sort the tasks, which allows tasks to move up the sort order in realtime as their urgency changes
   useEffect(() => {
       if(tasks) {
           let tasksToSort;
           //If there is no search term and no filters, include all tasks
           if(!searchTerm && (!filters || !filters.labels || !Object.values(filters.labels).some(filterStatuses => filterStatuses.include || filterStatuses.exclude))) {
               tasksToSort = tasks;
           } else {
               let searchFilteredTasks = tasks;
               if(searchTerm !== "") {
                   const fuse = new Fuse(tasks, { keys: ["name"], distance: 100, threshold: 0.4 });
                   searchFilteredTasks = fuse.search(searchTerm).map(result => result.item);
               }

               tasksToSort = searchFilteredTasks.filter((task) => {
                   // If the task has a label has a label in the selected filters, include it
                   // But if the task has a label which is not in the selected filters, exclude it
                   let hasExcludedLabel = Object.entries(filters.labels).some(([labelName, filterStatuses]) => {
                       return filterStatuses.exclude && task.labels?.includes(labelName);
                   });

                   // If the task has excluded label, we don't need to check for included labels
                   if(hasExcludedLabel) return false;

                   let hasIncludedLabel = Object.entries(filters.labels).some(([labelName, filterStatuses]) => {
                       return filterStatuses.include && task.labels?.includes(labelName);
                   });

                   // If no label is set to be included, then it means we show all tasks that do not have an excluded label
                   // Otherwise, we show only tasks that have at least one included label
                   return Object.values(filters.labels).every(filterStatus => !filterStatus.include) || hasIncludedLabel;
               })
           }
           const sortedTasks = tasksToSort.sort(smartSortTasks(attributeDefinitions));
           setDisplayTasks(sortedTasks);

           setForceSort(false);
       }
   }, [forceSort, tasks, filters, attributeDefinitions, searchTerm])

    return (
        <Droppable droppableId={listId} key={listId} ignoreContainerClipping={false}>
            {(provided) => (
                <div className="task-list__list" ref={provided.innerRef}>
                    {displayTasks && displayTasks.map((item, index) => (
                        <Task
                            item={item}
                            key={item.taskId}
                            index={index}
                            listId={listId}
                            isDraggable={!isMobile}
                            forceListToResort={() => {
                                setForceSort(true);
                            }}
                        />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
};

export default List
