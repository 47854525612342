import React from "react";
import TickerButton from "../input/button/TickerButton";

const Ticker = ({name, value, maxValue, onValueChange, invertColours}) => {
    const tickUp = () => {
        if(value + 1 <= maxValue) {
            onValueChange(1);
        }
    };

    const tickDown = () => {
        if(value - 1 >= 1) {
            onValueChange(-1);
        }
    };

    return (
        <div className="ticker">
            <p className="ticker__name">{name}</p>
            <div className="ticker__control-container">
                <TickerButton direction="down" onClick={tickDown}/>
                <div className={`ticker__display ticker__display--${invertColours ? 11 - value : value}`}>
                    <h3 className="ticker__display-label">{value}</h3>
                </div>
                <TickerButton direction="up" onClick={tickUp}/>
            </div>
        </div>
    )
};

export default Ticker;
