import {useSelector} from "react-redux";
import Banner from "./index";

const Banners = () => {
    const bannersState = useSelector(state => state.banners);

    const bannerArray = Object.entries(bannersState)
        .filter((banner) => {
            return banner[1].text //Make sure we actually have a text field
        })
        .map((bannerEntry) => {
            const key = bannerEntry[0];
            const banner = bannerEntry[1];
            return <Banner bannerKey={key} bannerText={banner.text} />
        })

    return (
        <div className="banners">
            {bannerArray}
        </div>
    )
}

export default Banners;