import React from "react";

const attributeValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const AttributeSelectionPanel = ({ onSelection }) => {
    return (
        <div className="attribute-selection-panel">
            {attributeValues.map(value => (
                <div className={`attribute-indicator attribute-indicator--${value}`} onClick={() => onSelection(value)}>
                    {value}
                </div>
            ))}
        </div>
    );
}

export default AttributeSelectionPanel;
