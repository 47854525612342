import React, {  useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { firebaseUI } from "../helpers/firebase"
import firebaseLoginConfig from "../config/firebase-login";
import FirebaseLogo from "../assets/firebase-logo.svg"

import Page from "./Page";

import 'firebaseui/dist/firebaseui.css'
import {Capacitor} from "@capacitor/core";
import {signInWithEmailAndPassword, signInWithGoogle} from "../helpers/auth";
import {dispatch} from "../redux/store";
import {setUser} from "../redux/actions/user";

const LoginPage = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.activeUser);

    // Firebase Auth UI Initializer
    // - Display Auth container once, if firebaseUI has been initialized
    useEffect(() => {
        if(!Capacitor.isNativePlatform()) {
            if (firebaseUI && !user) {
                firebaseUI.start('#firebaseui-auth-container-login-page', firebaseLoginConfig);
            } else if (!firebaseUI) {
                //TODO proper error handling here
                alert("Firebase UI setup failed")
            }
        }
    }, []);

    // Redirection Effect
    // - Redirect user to homepage if they are already logged in as a user
    useEffect(() => {
        if(user) {
            navigate("/")
        }
    }, [user]);

    const onGoogleLogin = async () => {
        try {
            const user = await signInWithGoogle()
            const {uid, email, displayName} = user;
            dispatch(setUser({
                uid,
                email,
                displayName
            }));
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page className="login-page" pageKey="login">
            <div className={"login-page__container"}>
                <h1 className="login-page__title">Login or Signup</h1>
                <p className="login-page__description">
                    If you do not already have an account with us, one will be created for you.
                </p>
                { Capacitor.isNativePlatform() ?
                    <div className="login-page__native-login-container">
                        <button
                            className="login-page__google-button"
                            onClick={onGoogleLogin}
                        >
                            <img className="login-page__google-button-logo"
                                 src="https://img.icons8.com/color/48/000000/google-logo.png"
                                 alt="Google Logo"
                            />
                            <span className="login-page__google-button-text">Login with Google</span>
                        </button>
                    </div>
                    :
                    <div id="firebaseui-auth-container-login-page" className="login-page__auth-container"/>
                }

                <div className="login-page__firebase-disclaimer">
                    <p className="login-page__sub-text">
                        Our authentication is securely handled by Google Firebase.
                        </p>
                    <p className="login-page__sub-text">
                        We do not handle any of your login information directly.
                    </p>

                    <img className="login-page__firebase-logo" src={FirebaseLogo} alt="Firebase Logo"/>
                </div>
            </div>
        </Page>
    )
};

export default LoginPage;
