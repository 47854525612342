import {CircularProgress, Typography} from "@mui/material";
import {paletteTheme} from "../../theme";
import Logo from "../../assets/mindstack-logo.png";
import {useEffect, useState} from "react";
import {Capacitor} from "@capacitor/core";

const LoadingScreen = () => {
    const [showSlowLoadWarning, setShowSlowLoadWarning] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowSlowLoadWarning(true);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <div className="loading-screen">
            <CircularProgress size={100} style={{color: paletteTheme.palette.white.main}}/>
            <img className="loading-screen_logo" src={Logo} />
            {showSlowLoadWarning &&
                (Capacitor.isNativePlatform() ?
                    <div className="loading-screen_slow-warning">
                        <Typography variant="body1" style={{color: paletteTheme.palette.white.main}}>
                            Either you are offline, or Mindstack's servers are experiencing issues.
                        </Typography>
                        <Typography variant="body1" style={{color: paletteTheme.palette.white.main}}>
                            We do not currently have offline support but it's on our to-do list!
                        </Typography>
                    </div>
                    :
                    <div className="loading-screen_slow-warning">
                        <Typography variant="body1" style={{color: paletteTheme.palette.white.main}}>
                            This is taking longer than expected. Sorry!
                        </Typography>
                        <Typography variant="body1" style={{color: paletteTheme.palette.white.main}}>
                            Mindstack is a new app, and our servers may be experiencing issues. Please try again later.
                        </Typography>
                    </div>
                )
            }
        </div>
    )
}

export default LoadingScreen;