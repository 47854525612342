import Page from "./Page";
import AboutTemplate from "../components/about/AboutTemplate";
import {Typography} from "@mui/material";

const AboutPage = () => {
    return (
        <Page className="about-page" pageKey="about">
            <AboutTemplate>
                <Typography variant="h1">About</Typography>
                <Typography variant="body1">You shouldn't really be here, you rascal</Typography>
                <Typography variant="body1">But since you are here, I hope you're having a good day mate</Typography>
            </AboutTemplate>
        </Page>
    );
}

export default AboutPage;