import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import { Typography } from "@mui/material";

const HowToUseMindstack = () => {
    return (
        <Page className="about-page how-to-use-mindstack-page" pageKey="how-to-use-mindstack">
            <AboutTemplate>
                <Typography variant="h1">How to use Mindstack</Typography>
                <Typography variant="body1">Give higher importance to tasks which you will want to focus on soon.</Typography>
            </AboutTemplate>
        </Page>
    );
}

export default HowToUseMindstack;