import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const RecurringTasks = () => {
    return (
        <Page className="about-page recurring-tasks-page" pageKey="recurring-tasks">
            <AboutTemplate>
                <Typography variant="h1">Recurring Tasks</Typography>
                <Section>
                    <Typography variant="body1">Recurring tasks are tasks that you need to do on a regular basis. You can set up a task to recur daily, weekly, monthly, or yearly.</Typography>
                    <Typography variant="body1">Recurring tasks are a great way to keep track of tasks that you need to do regularly, such as taking out the bins or watering your plants.</Typography>
                    <ResponsiveImage imageName="recurring-task" pageKey="recurringTasks" size="large" />
                </Section>
                <Section title="Setting a task to recur">
                    <Typography variant="body1">You can set a task to recur through either the <Link href="/about/features/tasks/create-a-task" variant="body1">"Create Task"</Link> or "Edit Task" pages.</Typography>
                    <ImageAndDescription imageName="recur-checkbox" pageKey="recurringTasks" description="Click the checkbox next to the 'Recur' input to set your task to recur." />
                    <Typography variant="body1">The task's recurrence will default to "Every week", but you can click the dropdown to select a recurrence interval.</Typography>
                    <ResponsiveImage imageName="dropdown" pageKey="recurringTasks" size="small" />
                    <Typography variant="body1">Begin by setting a "Start Date" which decides when your task will start to recur.</Typography>
                    <Typography variant="body1">Then select a recurrence type (Day/Week/Month/Year) and an interval (1-10).</Typography>
                    <Typography variant="body1">Once the task has been completed, it will be re-added to your task list when enough time has passed.</Typography>
                </Section>
                <Section>
                    <Typography variant="h3">Example</Typography>
                    <Typography variant="body1">For example, a task may be set to recur on 1st August with a 1 month interval.</Typography>
                    <Typography variant="body1">The task is completed on the 10th August and removed from your task list.</Typography>
                    <Typography variant="body1">The task will remain completed for the rest of the month, but at midnight on the 1st September the task will be re-added to the task list.</Typography>
                    <Typography variant="body1">This will repeat indefinitely until the task is deleted or recurrence is turned off for the task.</Typography>
                </Section>
                <Section title="Removing recurrence">
                    <ImageAndDescription imageName="remove-recurrence" pageKey="recurringTasks" description="You can remove a task's recurrence by clicking the recurrence input's checkbox again." />

                    <Typography variant="body1">A task may either recur, or have a deadline. Not both. If you enable recur whilst a task has a deadline set, the deadline will be removed.</Typography>
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default RecurringTasks;