import TaskSummary from "../../tasks/TaskSummary";
import Button from "../../input/button";
import ShuffleButton from "../../input/button/ShuffleButton";
import React, {useCallback, useEffect, useState} from "react";
import {setOverlayVisible} from "../../../redux/actions/overlay";
import {useDispatch, useSelector} from "react-redux";
import socket from "../../../socket";
import {setTaskTargeted} from "../../../redux/actions/tasks";
import {smartSortTasks} from "../../../helpers/list";
import useWindowDimensions from "../../../helpers/window";

const TaskSelection = ({ mindset }) => {
    const dispatch = useDispatch();
    const taskSet = useSelector(state => state.tasks.taskSet);
    const [numberOfTasks, setNumberOfTasks] = useState(3);
    const [suggestedTasks, setSuggestedTasks] = useState([]);
    const [previouslySuggestedTasks, setPreviouslySuggestedTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState();
    const attributeDefinitions = useSelector(state => state.definitions.attributes);
    const { height } = useWindowDimensions();

    const suggestTasks = useCallback((shuffle) => {
        let prevSuggested = shuffle ? [...previouslySuggestedTasks] : []

        let sortedTasks = Object.values(taskSet)
            .filter(task => !task.targeted && !prevSuggested.includes(task.taskId))
            .sort(smartSortTasks(attributeDefinitions, mindset));

        // If no tasks available, reset the cycle
        if (sortedTasks.length === 0) {
            prevSuggested = [];
            setPreviouslySuggestedTasks([])
            sortedTasks = Object.values(taskSet)
                .filter(task => !task.targeted)
                .sort(smartSortTasks(attributeDefinitions, mindset));
        }

        const newTasks = sortedTasks.slice(0, numberOfTasks);

        // Add new tasks id to previouslySuggestedTasks
        prevSuggested.push(...newTasks.map(task => task.taskId));

        setPreviouslySuggestedTasks(prevSuggested);
        setSuggestedTasks(newTasks);
    }, [taskSet, attributeDefinitions, mindset, numberOfTasks, previouslySuggestedTasks]);

    useEffect(() => {
        setPreviouslySuggestedTasks([]);
        suggestTasks(false);
    }, [taskSet, attributeDefinitions, mindset, numberOfTasks]);

    // This useEffect will be triggered once the component mounts and whenever height changes
    useEffect(() => {
        // Update the number of tasks based on window height
        if (height < 700) {
            setNumberOfTasks(2);
        } else if((height >= 700 && height < 1000)) {
            setNumberOfTasks(3)
        } else {
            setNumberOfTasks(3);
        }
    }, [height]);

    const onTaskSelected = (taskId) => {
        setSelectedTask(taskId);
    }

    const onCancelClick = () => {
        dispatch(setOverlayVisible({visible: false}))
    }

    const onSelectClick = () => {
        socket.emit("setTaskTargeted", {
            taskId: selectedTask,
            targeted: true
        });

        dispatch(setTaskTargeted({taskId: selectedTask, targeted: true}));
        dispatch(setOverlayVisible({visible: false}))
    }


    return (
        <div className="task-selection_container">
            <h2 className="task-selection_title">Suggested Tasks</h2>
            <div className="task-selection_task-list">
                {
                    suggestedTasks.length >= 1 ?
                        suggestedTasks.map((task, index) =>
                            <TaskSummary
                                key={index}
                                item={task}
                                onClick={onTaskSelected}
                                selected={selectedTask === task.taskId}
                            />
                        ) :
                        <h3 className="get-task-overlay_no-tasks-label">No tasks</h3>
                }
            </div>
            <div className="task-selection_button-container">
                <Button text="Cancel" color="red" icon="close" onClick={onCancelClick}/>
                <ShuffleButton onClick={() => suggestTasks(true)} />
                <Button text="Target" color="green" icon="tick" disabled={!selectedTask} onClick={onSelectClick}/>
            </div>
        </div>
    )
}

export default TaskSelection;
