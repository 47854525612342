import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import { Typography } from "@mui/material";
import Section from "../../components/about/Section";
import ImageAndDescription from "../../components/about/ImageAndDescription";
import ResponsiveImage from "../../components/about/ResponsiveImage";

const TaskSearch = () => {
    return (
        <Page className="about-page task-search-page" pageKey="task-search">
            <AboutTemplate>
                <Typography variant="h1">Task search</Typography>
                <Section>
                    <Typography variant="body1">Task search is a way to find a specific task in your stack.</Typography>
                    <ImageAndDescription imageName="search-button" pageKey="taskSearch" description="You can search for a task by clicking the 'Search' icon at the bottom of the screen." />
                </Section>
                <Section>
                    <Typography variant="body1">Clicking the search icon will open a search bar.</Typography>
                    <ResponsiveImage pageKey="taskSearch" imageName="search-bar" size="small" />
                </Section>
                <Section>
                    <Typography variant="body1">You can type in the search bar to find a task by its title.</Typography>
                    <Typography variant="body1">This will update your task stack to only show tasks which have titles that match the search term.</Typography>
                    <ResponsiveImage pageKey="taskSearch" imageName="search-results" size="medium" />
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default TaskSearch;