import { ValueConstants } from "../constants";
import dayjs from "dayjs";
import useWindowDimensions from "../helpers/window";
import {useCallback, useEffect, useRef, useState} from "react";

const useTaskDeadline = (deadline, created, forceListToResort) => {
    const { width } = useWindowDimensions();
    const [deadlineDisplay, setDeadlineDisplay] = useState(null);
    const [closeToDeadline, setCloseToDeadline] = useState(false);

    const calculateDeadline = useCallback(() => {
        if(!deadline) {
            return;
        }

        const timeTillDeadline = deadline - dayjs().unix();

        if (timeTillDeadline < 0) {
            setDeadlineDisplay("Overdue");
            setCloseToDeadline(true);
        } else {
            const { SECONDS_IN_DAY, SECONDS_IN_HOUR } = ValueConstants;
            const createdInSeconds = Math.round(created / 1000);
            const timeToComplete = deadline - createdInSeconds;
            const timeToCompletePercent = Math.round((timeTillDeadline / timeToComplete) * 100);

            if (timeToCompletePercent <= 10) {
                setCloseToDeadline(true);
            } else {
                setCloseToDeadline(false);
            }

            if (timeTillDeadline < SECONDS_IN_DAY) {
                const unroundHoursToComplete = timeTillDeadline / SECONDS_IN_HOUR;
                const hoursToComplete = Math.round(unroundHoursToComplete);
                const hoursDisplay = width < 768 ? 'H' : (hoursToComplete <= 1) ? 'Hour' : 'Hours';

                if (hoursToComplete < 1) {
                    setDeadlineDisplay(`${width > 380 ? "Under" : "<"} 1 ${hoursDisplay}`);
                } else {
                    setDeadlineDisplay(`${hoursToComplete} ${hoursDisplay}`);
                }
            } else {
                const daysToComplete = Math.round(timeTillDeadline / SECONDS_IN_DAY);
                const daysDisplay = width < 768 ? 'D' : (daysToComplete === 1) ? 'Day' : 'Days';

                setDeadlineDisplay(`${daysToComplete} ${daysDisplay}`);
            }
        }
    }, [deadline, created])

    const timerRef = useRef(null);
    useEffect(() => {
        if(deadline) {
            timerRef.current = setInterval(() => {
                calculateDeadline();
                forceListToResort();
            }, 15000);

            return () => {
                clearInterval(timerRef.current);
            }
        }
    }, [])

    useEffect(() => {
        calculateDeadline();
    }, [deadline, width])

    return { deadlineDisplay, closeToDeadline }
}

export default useTaskDeadline;