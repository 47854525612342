import { Typography } from "@mui/material";

const Section = ({ title, children }) => (
    <div className="about-section">
        {title && <Typography variant="h2">{title}</Typography>}
        <div className="about-section_content">
            {children}
        </div>
    </div>
)

export default Section;