import {SET_ACTIVE_SESSIONS, SET_SESSION_HISTORY, SET_SESSIONS, SET_USER_IS_ADMIN, SET_USERS} from "../constants/admin";

export const setUserIsAdmin = (isAdmin) => {
    return {
        type: SET_USER_IS_ADMIN,
        isAdmin
    }
}

export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users
    }
}

export const setActiveSessions = (activeSessions) => {
    return {
        type: SET_ACTIVE_SESSIONS,
        activeSessions
    }
}

export const setSessions = (sessions) => {
    return {
        type: SET_SESSIONS,
        sessions
    }
}

export const setSessionHistory = (sessionHistory) => {
    return {
        type: SET_SESSION_HISTORY,
        sessionHistory
    }
}