import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import {Link, Typography} from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";
import DeveloperNotes from "../../components/about/DeveloperNotes";

const Mindset = () => {
    return (
        <Page className="about-page mindest-page" pageKey="mindset">
            <AboutTemplate>
                <Typography variant="h1">Mindset</Typography>
                <Section>
                    <Typography variant="body1">Mindset is a way to track how you're feeling.</Typography>
                    <Typography variant="body1">Once you have set your mindset, Mindstack can <Link href="/about/features/task-suggestions" variant="body1">suggest tasks</Link> based on how you are feeling.</Typography>
                    <ResponsiveImage imageName="mindset-selector" pageKey="mindset" size="large" />
                </Section>

                <Section title="Setting your mindset">
                    <ImageAndDescription imageName="mindset-icon" pageKey="mindset" description="You can set your mindset by clicking the smiley face icon in the bottom right of your screen." />
                </Section>

                <Section title="Mood">
                    <Typography variant="body1">Mood is a measure of how you generally feel in yourself. How are you feeling?</Typography>
                    <Typography variant="body1">You can set your mood using the slider with the smiley face icon.</Typography>
                    <ResponsiveImage imageName="mood-slider" pageKey="mindset" size="small" />

                    <Typography variant="body1">Your mood is used to recommend tasks based on how fun they are.</Typography>
                    <Typography variant="body1">If you are in a bad mood, more fun tasks will be recommended. If are in a good mood, less fun will be recommended.</Typography>
                </Section>

                <Section title="Energy">
                    <Typography variant="body1">Energy is a measure of how much energy you have to complete tasks.</Typography>
                    <Typography variant="body1">You can set your energy using the slider with the battery icon.</Typography>
                    <ResponsiveImage imageName="energy-slider" pageKey="mindset" size="small" />

                    <Typography variant="body1">Your energy level is used to recommend tasks based on the difficulty level of the task.</Typography>
                    <Typography variant="body1">If you have low energy, easier tasks will be recommended. If you have more energy, harder tasks will be recommended.</Typography>
                </Section>

                <DeveloperNotes>
                    <Typography variant="body1">I've found the Mindset feature to be most useful when you aren't feeling your usual self.</Typography>
                    <Typography variant="body1">If you feel pretty normal, it is best to select tasks from your stack based on their importance.</Typography>
                    <Typography variant="body1">However, if you are feeling especially good or especially bad the Mindset feature begins to shine, as you can focus on tasks that are particularly easy or difficult.</Typography>
                    <Typography variant="body1">Especially when you are feeling particularly bad, use the Mindset feature to find easy tasks and get some quick wins, and use this to snowball your motivation until you start feeling better.</Typography>
                </DeveloperNotes>
            </AboutTemplate>
        </Page>
    );
}

export default Mindset;