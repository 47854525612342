import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import { Typography } from "@mui/material";

const Stacks = () => {
    return (
        <Page className="about-page stacks-page" pageKey="stacks">
            <AboutTemplate>
                <Typography variant="h1">Stacks</Typography>
                <Typography variant="body1">Stacks are a way to group tasks together.</Typography>
                <Typography variant="body1">Stacks will automatically sort your tasks into order based on the task's importance</Typography>
                <Typography variant="body1">Stacks will filter tasks based on the currently selected filters or search term</Typography>
                <Typography variant="body1">Targetted tasks will appear in a separate stack on desktop, or at the top of your stack on mobile</Typography>
            </AboutTemplate>
        </Page>
    );
}

export default Stacks;