import Page from "./Page";
import Splash from "../components/home/Splash";
import TaskPrioritization from "../components/home/TaskPrioritization";
import LabelsAndNotes from "../components/home/LabelsAndNotes";
import Deadlines from "../components/home/Deadlines";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {dispatch} from "../redux/store";
import {setInitialLoad} from "../redux/actions/session";
import LoadingScreen from "../components/loading/LoadingScreen";

const HomePage = () => {
    const navigate = useNavigate();
    const initialLoad = useSelector(state => state.session.initialLoad);
    const authenticated = useSelector(state => state.user.authenticated);
    const user = useSelector(state => state.user.activeUser);

    useEffect(() => {
        if (initialLoad && authenticated) {
            navigate("/tasks");
            dispatch(setInitialLoad(false));
        }
    }, [initialLoad, authenticated]);

    if(user === null) {
        return <LoadingScreen />
    }

    return (
        <Page className="home-page" pageKey="home">
            <Splash />
            <TaskPrioritization />
            <LabelsAndNotes />
            <Deadlines />
        </Page>
    )
};

export default HomePage;