import {LocalNotifications} from "@capacitor/local-notifications";

const waitForLocalNotificationsPermission = async () => {
    return new Promise((resolve, reject) => {
        let interval = setInterval(async () => {
            const permissions = await LocalNotifications.checkPermissions();
            if (permissions.display === 'granted') {
                clearInterval(interval);
                resolve();
            } else if (permissions.display === 'denied') {
                clearInterval(interval);
                reject(new Error('Local notification permission denied'));
            }
        }, 1000)
    });
}

export default waitForLocalNotificationsPermission;