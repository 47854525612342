import LabelList from "./LabelList";
import React, {useState} from "react";
import socket from "../../socket";
import {ReactComponent as PlusIcon} from "../../assets/icons/add_black_24dp.svg";
import Input from "../input";
import {ReactComponent as CheckIcon} from "../../assets/icons/check_black_24dp.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close_black_24dp.svg";

const EditLabelsPanel = ({
    selectedLabels,
    onSelectedLabelsChange,
    onLabelRemoved
}) => {
    const [newLabelText, setNewLabelText] = useState("");
    const [showNewLabelRow, setShowNewLabelRow] = useState(false);

    const onLabelInputChange = (event) => {
        const labelText = event.target.value

        if(labelText.length <= 16 ){
            setNewLabelText(labelText);
        }
    }

    const onNewLabelButtonClicked = () => {
        setShowNewLabelRow(true);
    }

    const onCancelNewLabelButtonClicked = () => {
        setNewLabelText("");
        setShowNewLabelRow(false);
    }

    const onNewLabelCreated = () => {
        if(newLabelText) {
            const newLabelsArray = Array.from(selectedLabels);
            newLabelsArray.push(newLabelText);
            onSelectedLabelsChange(newLabelsArray)

            socket.emit("addNewLabel", { labelName: newLabelText });
            setNewLabelText("");
        }
        setShowNewLabelRow(false);
    }

    return (
        <div className="edit-labels-panel">
            <LabelList selectedLabels={selectedLabels} onSelectedLabelsChange={onSelectedLabelsChange} onLabelRemoved={onLabelRemoved}/>
            <div className="edit-labels-panel_new-label-container">
                {showNewLabelRow ?
                    <div className="edit-labels-panel_new-label-row">
                        <Input
                            autoFocus={true}
                            value={newLabelText}
                            onChange={onLabelInputChange}
                            onEnterPressed={onNewLabelCreated}
                            startAdornment={
                                <CloseIcon
                                    className="edit-labels-panel_cancel-new-label-button"
                                    onClick={onCancelNewLabelButtonClicked}
                                />
                            }
                            endAdornment={
                                <CheckIcon
                                    className="edit-labels-panel_create-label-button"
                                    onClick={onNewLabelCreated}
                                />
                            }
                        />
                    </div>
                    :
                    <PlusIcon className="edit-labels-panel_new-label-button" onClick={onNewLabelButtonClicked}/>
                }
            </div>
        </div>
    )
}

export default EditLabelsPanel;