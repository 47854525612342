import palette from "../palette";

const MuiLink = {
    styleOverrides: {
        root: {
            textDecoration: "none",
            cursor: "pointer",
            color: palette.link.main,
            fontSize: "1.2rem",
            fontWeight: "semiBold",
            "&:hover": {
                color: palette.link.dark
            },

            '@media (min-width: 768px)': {
                fontSize: "1.3rem",
            }
        }
    }
}

export default MuiLink;