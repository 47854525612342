import AddReactionIcon from "@mui/icons-material/AddReaction";
import {IconButton} from "@mui/material";
import React, {useState} from "react";
import Picker, { Emoji } from "emoji-picker-react";

const EmojiPickerContainer = ({ setEmoji, emoji }) => {
    const [selectorOpen, setSelectorOpen] = useState(false);
    const [emojiIcon, setEmojiIcon] = useState(emoji);
    return (
        <div className="emoji-picker">
            <IconButton
                className="emoji-picker_emoji-icon-container"
                onClick={() => setSelectorOpen(!selectorOpen)}
            >
                {emojiIcon ?
                    <Emoji unified={emojiIcon} set="apple" size={40} />
                    :
                    <AddReactionIcon className="emoji-picker_emoji-icon" />
                }
            </IconButton>
            {
                selectorOpen &&
                    <Picker
                        className={"emoji-picker_selector"}
                        onEmojiClick={(emojiObject, event) => {
                            setEmoji(emojiObject.unified);
                            setEmojiIcon(emojiObject.unified);
                            setSelectorOpen(false);
                        }}
                        height={350}
                        width={320}
                    />
            }
        </div>
    )
}

export default EmojiPickerContainer;