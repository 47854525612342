import React from 'react';
import { useDispatch } from "react-redux";
import Button from "../input/button"
import {setOverlayVisible} from "../../redux/actions/overlay";

const ConfirmOverlay = ({ confirmCallback, cancelCallback, confirmationText, overlaySource }) => {
    const dispatch = useDispatch();

    return (
        <div className="confirm-overlay">
            <h3 className="confirm-overlay__confirmation-text">{confirmationText}</h3>
            <div className="confirm-overlay__footer">
                <Button text="Cancel" color="red" size="large" onClick={() => {
                    if(cancelCallback) {
                        cancelCallback();
                    }

                    if(overlaySource) {
                        dispatch(setOverlayVisible({visible: true, ...overlaySource}))
                    } else {
                        dispatch(setOverlayVisible({visible: false}))
                    }
                }}/>
                <Button text="Confirm" color="green" size="large" confetti={true} onClick={() => {
                    if(confirmCallback) {
                        confirmCallback();
                    }

                    if(overlaySource) {
                        dispatch(setOverlayVisible({visible: true, ...overlaySource}))
                    }
                }}/>
            </div>
        </div>
    )
}

export default ConfirmOverlay;