import {Grid} from "@mui/material";
import LabelsImage from "../../assets/labels.png";
import LabelsMobileImage from "../../assets/labels_mobile.png";
import NotesImage from "../../assets/notes.png";
import NotesMobileImage from "../../assets/notes_mobile.png";
import useWindowDimensions from "../../helpers/window";

const LabelsAndNotes =  () => {
    const { width } = useWindowDimensions();
    return (
        <Grid className="labels-and-notes">
            <Grid container className="labels">
                <Grid item xs={12} md={6} className="home-page_feature-image-container">
                    <img src={LabelsMobileImage} className="home-page_feature-image task-prioritization_mood-selection-image"/>
                </Grid>
                <Grid item xs={12} md={4}  className="home-page_feature-text-container">
                    <h2 className="home-page_feature-header">Label your tasks</h2>
                    <p className="home-page_feature-text">Add labels to allow you to group and filter your tasks</p>
                </Grid>
                <Grid item xs={12} md={2} />
            </Grid>
            <Grid container className="notes">
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={4}  className="home-page_feature-text-container">
                    <h2 className="home-page_feature-header">Add notes</h2>
                    <p className="home-page_feature-text">Add notes to keep all information for a task in one place</p>
                </Grid>
                <Grid item xs={12} md={6} className="home-page_feature-image-container">
                    <img src={width > 1200 ? NotesImage: NotesMobileImage} className="home-page_feature-image task-prioritization_mood-selection-image"/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LabelsAndNotes;