import useWindowDimensions from "../../helpers/window";

const ResponsiveImage = ({ imageName, pageKey, size = "full", shadow }) => {
    const { width } = useWindowDimensions();
    const isMobile = width < 768;


    const image = require(`../../assets/about/${pageKey}/${imageName}${isMobile ? "-mobile" : ""}.png`);

    return <div className={`responsive-image-container`}>
        <img src={image} alt={imageName} className={`${isMobile ? "responsive-image--mobile" : "responsive-image"} responsive-image--${size}${shadow ? " responsive-image--shadow" : ""}`} />
    </div>
}

export default ResponsiveImage;