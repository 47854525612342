
// This component is used by both the CreateTask and EditTask page and allows us to have separate routes for the two pages
// but make sure they both stay consistent with any visual or functional changes
import React from "react";
import Input from "../input";
import LabelContainer from "../labels/LabelContainer";
import EditLabelsPanel from "../labels/EditLabelsPanel";
import {Grid} from "@mui/material";
import DeadlineContainer from "./taskInputs/DeadlineContainer";
import TargetToggleContainer from "./taskInputs/TargetToggleContainer";
import AttributesContainer from "./taskInputs/AttributesContainer";
import NotesContainer from "./taskInputs/notes/NotesContainer";
import RecurringTasksContainer from "./taskInputs/recurringTasks/RecurringTasksContainer";
import EmojiPickerContainer from "./taskInputs/EmojiPickerContainer";

// TODO find best way to get rid of gigaprops
const TaskDataContainer = ({
    name,
    onNameChange,
    emoji,
    setEmoji,
    labels,
    onLabelsChange,
    onLabelRemoved,
    deadline,
    setHasDeadline,
    hasDeadline,
    setDeadline,
    isRecurring,
    setRecurring,
    frequency,
    setFrequency,
    targeted,
    setTargeted,
    attributes,
    onAttributeChange,
    notes,
    onAddNote,
    onNoteStatusChange,
    onNoteDeleted,
    showEditLabelsPanel,
    onChangeEditLabelPanelVisibility,
    noteInputValueRef
}) => {
    return (
    <Grid container className="task-data-container__contents scroller">
        <div className="task-data-container__name-container">
            <EmojiPickerContainer emoji={emoji} setEmoji={setEmoji}/>
            <Input placeholder="Task Name" labelText="Task Name" onChange={onNameChange} value={name}/>
            <TargetToggleContainer
                targeted={targeted}
                setTargeted={setTargeted}
            />
        </div>
        <LabelContainer
            editPanelIsOpen={showEditLabelsPanel}
            selectedLabels={labels}
            onChangeEditLabelPanelVisibility={onChangeEditLabelPanelVisibility}
        />
        {
            showEditLabelsPanel
                ?
                <EditLabelsPanel
                    selectedLabels={labels}
                    onSelectedLabelsChange={onLabelsChange}
                    onLabelRemoved={onLabelRemoved}
                />
                :
                <Grid container>
                    <AttributesContainer attributes={attributes} onAttributeChange={onAttributeChange} />
                    <Grid container justifyContent="space-around" alignItems="center" spacing={{ xs: 0, sm: 4}}>
                        <Grid item xs={12} sm={6}>
                            <RecurringTasksContainer
                                frequency={frequency}
                                isRecurring={isRecurring}
                                setRecurring={setRecurring}
                                setFrequency={setFrequency}
                                hasDeadline={hasDeadline}
                                setHasDeadline={setHasDeadline}
                                setDeadline={setDeadline}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeadlineContainer
                                deadline={deadline}
                                setHasDeadline={setHasDeadline}
                                hasDeadline={hasDeadline}
                                setDeadline={setDeadline}
                                isRecurring={isRecurring}
                                setRecurring={setRecurring}
                                setFrequency={setFrequency}
                            />
                        </Grid>
                    </Grid>
                    <NotesContainer onAddNote={onAddNote} notes={notes} onNoteStatusChange={onNoteStatusChange} onNoteDeleted={onNoteDeleted} noteInputValueRef={noteInputValueRef} />
                </Grid>
        }
    </Grid>)
}

export default TaskDataContainer;