import {Card, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {getDatabase, ref, child, get, off} from "firebase/database";

const GlobalStats = () => {
    const [taskStats, setTaskStats] = useState({});
    const [userStats, setUserStats] = useState({});

    const database = getDatabase();

    useEffect(() => {
        if(!database) return;

        const dbRef = ref(getDatabase());

        get(child(dbRef, "admin/analytics/tasks")).then((snapshot) => {
            setTaskStats(snapshot.val());
        });
        get(child(dbRef, "admin/analytics/users")).then((snapshot) => {
            setUserStats(snapshot.val());
        });
        return () => {
            off(dbRef);
        }
    }, [database]);
    return (
        <Card sx={{ width: "100%", padding: {xs: 2, md: 4}}}>
            <Typography variant="h2">Global Stats</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h3">Users</Typography>
                    <Typography variant="body1">Total: {userStats.total}</Typography>
                    <Typography variant="body1">Total Logins: {userStats.totalLogins}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h3">Tasks</Typography>
                    <Typography variant="body1">Total: {taskStats.total}</Typography>
                    <Typography variant="body1">Completed: {taskStats.completed}</Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export default GlobalStats;