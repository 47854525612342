import pageDataListener from "./pageData";
import authenticatedListener from "./authenticated";
import connectionErrorListener from "./connectionError";
import confirmTaskCreationListener from "./tasks/confirmTaskCreation";
import addNewLabelListener from "./tasks/addNewLabel";
import removeLabelListener from "./tasks/removeLabel";
import removeLabelFromTaskListener from "./tasks/removeLabelFromTask";
import newBannerListener from "./newBanner";

export const attachListenersToSocket = (socket) => {
    socket.on("pageData", pageDataListener);
    socket.on("authenticated", authenticatedListener);

    socket.on("confirmTaskCreation", confirmTaskCreationListener);
    socket.on("addNewLabel", addNewLabelListener)
    socket.on("removeLabel", removeLabelListener)
    socket.on("removeLabelFromTask", removeLabelFromTaskListener)

    socket.on("newBanner", newBannerListener)
    socket.on("connect_error", connectionErrorListener);
}