const createTypography = (theme) => ({
    fontFamily: [
        "poppins",
        "sans-serif",
    ].join(","),
    h1: {
        fontSize: "2rem",
        marginBottom: "1.6rem",
        color: theme.palette.primary.dark,

        [theme.breakpoints.up("md")]: {
            fontSize: "2.6rem",
            marginBottom: "2rem",
        },
    },
    h2: {
        fontSize: "1.6rem",
        marginBottom: "1rem",
        color: theme.palette.primary.dark,

        [theme.breakpoints.up("md")]: {
            fontSize: "2rem",
            marginBottom: "1.5rem",
        },
    },
    h3: {
        fontSize: "1.2rem",
        marginBottom: "0.8rem",
        color: theme.palette.primary.dark,

        [theme.breakpoints.up("md")]: {
            fontSize: "1.6rem",
            marginBottom: "1rem",
        },
    },
    headerTitle: {
        fontSize: "1.6rem",
        lineHeight: "1.6rem",
        marginBottom: "0",
        color: "white",

        [theme.breakpoints.up("md")]: {
            fontSize: "2rem",
            lineHeight: "2rem",
            marginBottom: "2rem",
        },
    },
    body1: {
        fontSize: "1.2rem",
        marginBottom: "0.8rem",

        [theme.breakpoints.up("md")]: {
            fontSize: "1.3rem",
            marginBottom: "1rem",
        },
    },
    body2: {
        fontSize: "0.7rem",
        marginBottom: "0.6rem",

        [theme.breakpoints.up("md")]: {
            fontSize: "0.9rem",
            marginBottom: "0.8rem",
        },
    },
    popoverMenuItem: {
        color: "white",
        fontSize: "1rem",
        marginBottom: "0.8rem",
        fontWeight: "bold",

        [theme.breakpoints.up("md")]: {
            fontSize: "1.2rem",
            marginBottom: "0.8rem",
        },
    },
});

export default createTypography;