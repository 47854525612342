import AboutTemplate from "../../components/about/AboutTemplate";
import Page from "../Page";
import { Typography } from "@mui/material";
import Section from "../../components/about/Section";
import ResponsiveImage from "../../components/about/ResponsiveImage";
import ImageAndDescription from "../../components/about/ImageAndDescription";

const TaskNotes = () => {
    return (
        <Page className="about-page task-notes-page" pageKey="task-notes">
            <AboutTemplate>
                <Section>
                    <Typography variant="h1">Task notes</Typography>
                    <Typography variant="body1">
                        Task notes are a way to add additional information to a task. This can be used to provide context, instructions, or any other information that is relevant to the task.
                    </Typography>
                    <ResponsiveImage imageName="task-notes" pageKey="taskNotes" size="medium" shadow />
                </Section>
                <Section title="Adding task notes">
                    <Typography variant="body1">
                        You can add notes to a task through the Create or Edit Task pages, by typing the note text into the 'New Note' field.
                    </Typography>
                    <ResponsiveImage imageName="create-note" pageKey="taskNotes" size="medium" />
                </Section>
                <Section>
                    <ImageAndDescription imageName="add-note-button" pageKey="taskNotes" description="Once you have filled in the note text, click the plus icon to add the note to the task" />
                </Section>
                <Section>
                    <Typography variant="body1"> Once you have added the note, it will appear in a list at the bottom of the task details section.</Typography>
                    <ResponsiveImage imageName="note-created" pageKey="taskNotes" size="medium" />
                </Section>
                <Section title="Completing task notes">
                    <Typography variant="body1">
                        To mark a task note as complete, click the checkbox next to the note text. The note will be crossed out to indicate that it has been completed.
                    </Typography>
                    <ResponsiveImage imageName="complete-note" pageKey="taskNotes" size="medium" />
                </Section>
                <Section title="Removing task notes">
                    <Typography variant="body1">
                        To remove a task note, click the cross icon next to the note text. The note will be deleted from the task after confirmation through a modal.
                    </Typography>
                    <ResponsiveImage imageName="remove-note" pageKey="taskNotes" size="medium" />
                </Section>
            </AboutTemplate>
        </Page>
    );
}

export default TaskNotes;