import {LocalNotifications} from "@capacitor/local-notifications";
import {hashStringToInt} from "../helpers/hash";
import {Preferences} from "@capacitor/preferences";
import calculateNotificationTimes from "./calculateNotificationTimes";
import store from "../redux/store";

const scheduleNotificationsForTask = async (taskId, createdTimestamp) => {
    //Get task definition from redux store
    const task = store.getState().tasks.taskSet[taskId];

    const deadline = task.deadline * 1000;

    //Check if task already has notifications scheduled
    const existingNotifications = await Preferences.get({ key: `task_${task.taskId}_notifications` });
    if(existingNotifications.value) {
        return;
    }

    const { nearlyDue, overdue } = calculateNotificationTimes(createdTimestamp ?? task.created, deadline);

    //Schedule overdue notification
    const overdueNotificationId = hashStringToInt(`${task.taskId}-deadline`);
    await LocalNotifications.schedule({
        notifications: [
            {
                title: "Task overdue",
                body: `"${task.name}" is now overdue`,
                id:  overdueNotificationId,
                schedule: { at: overdue },
                extra: task.taskId
            }
        ]
    });
    console.log("Scheduled overdue notification for task:", task.taskId)

    //Schedule notification when 90% of time between creation and deadline has passed
    const nearlyDueNotificationId = hashStringToInt(`${task.taskId}-deadline-approaching`);
    await LocalNotifications.schedule({
        notifications: [
            {
                title: "Task deadline approaching",
                body: `"${task.name}" is due soon`,
                id: nearlyDueNotificationId,
                schedule: { at: nearlyDue },
                extra: task.taskId
            }
        ]
    });

    console.log("Scheduled deadline approaching notification for task:", task.taskId)

    // Update preferences with notification ids
    await Preferences.set({
        key: `task_${task.taskId}_notifications`,
        value: JSON.stringify({
            nearlyDueNotificationId,
            overdueNotificationId
        }),
    });
}

export default scheduleNotificationsForTask;