import { ThemeProvider } from "@mui/material";
import {RouterProvider} from "react-router-dom";

import theme from "./theme";
import { router } from "./navigation/router";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
  );
}

export default App;
